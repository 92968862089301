import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { connect } from 'react-redux';
import { loggedIn } from '../../../ducks/auth.duck';
import { createStructuredSelector } from 'reselect';

const UnauthorizedRoute = props => {
	const {
		component: Component,
		authenticated,
		handlePathChange,
		...rest
	} = props;
	useEffect(() => {
		handlePathChange();
	}, [window.location.pathname]);
	if (!authenticated) {
		return <Component {...rest} />;
	}
	return <Redirect to="/" noThrow />;
};

UnauthorizedRoute.propTypes = {
	component: PropTypes.shape().isRequired,
	path: PropTypes.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
	authenticated: loggedIn(),
});
const mapDispatchToProps = dispatch => ({
	handlePathChange: () => dispatch({ type: 'PATH_CHANGED' }),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UnauthorizedRoute);
