// @TODO add tests
import {
	CognitoUserPool,
	CognitoUserAttribute,
	AuthenticationDetails,
	CognitoUser,
} from 'amazon-cognito-identity-js';
import request from './request';
import { POOL_DATA } from '../constants';
import { saveToLocalStorage, getFromLocalStorage } from './common-utils';

class AuthApi {
	fetchPoolData = () => {
		const init = {
			url: 'config',
		};
		const poolData = getFromLocalStorage(POOL_DATA);
		if (poolData !== null) {
			return Promise.resolve(poolData);
		}
		return request(init)
			.then(response => response.json())
			.then(data => {
				saveToLocalStorage(POOL_DATA, data);
				return data;
			});
	};

	setUserPool = data => {
		this.userPool = new CognitoUserPool(data);
	};

	setIdToken = idToken => {
		this.idToken = idToken;
	};

	getIdToken = () => {
		return this.idToken.getJwtToken();
	};

	registerUser = ({ username, email, password }) => {
		const dataUsername = {
			Name: 'preferred_username',
			Value: username,
		};
		const attributeList = [new CognitoUserAttribute(dataUsername)];

		return new Promise((resolve, reject) => {
			this.userPool.signUp(
				email,
				password,
				attributeList,
				null,
				(err, result) => {
					if (err) {
						reject(err);
					} else {
						resolve(result);
					}
				},
			);
		}).then(result => {
			this.registeredUser = result;
			this.setUserToLocalStorage(result);
		});
	};

	confirmRegistration = ({ code }) => {
		const { user: cognitoUser } = this.registeredUser || {
			user: this.getUserFromLocalStorage(),
		};
		if (cognitoUser) {
			return new Promise((resolve, reject) => {
				cognitoUser.confirmRegistration(code, true, (err, result) => {
					if (err) {
						reject(err);
					} else {
						this.removeUserFromLocalStorage();
						resolve(result);
					}
				});
			});
		}
		return new Promise((resolve, reject) => {
			reject(new Error('no registered user'));
		});
	};

	getCurrentUser = () => {
		const cognitoUser = this.userPool.getCurrentUser();
		if (cognitoUser != null) {
			return new Promise((resolve, reject) => {
				cognitoUser.getSession((err, session) => {
					if (err) {
						reject(new Error('getSession has failed'));
					} else {
						if (session.idToken) {
							this.setIdToken(session.idToken);
						}
						resolve(session);
					}
				});
			});
		}
		return Promise.reject(new Error('no current user'));
	};

	authenticateUser = ({ email, password }) => {
		const authenticationData = {
			Username: email,
			Password: password,
		};
		const authenticationDetails = new AuthenticationDetails(authenticationData);
		const userData = {
			Username: email,
			Pool: this.userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		return new Promise((resolve, reject) => {
			cognitoUser.authenticateUser(authenticationDetails, {
				onSuccess(result) {
					resolve(result);
				},
				onFailure(err) {
					reject(err);
				},
			});
		});
	};

	logoutUser = () => {
		const cognitoUser = this.userPool.getCurrentUser();
		if (cognitoUser != null) {
			cognitoUser.signOut();
		}
	};

	setUserToLocalStorage = ({ user }) => {
		localStorage.setItem('notConfirmedUser', user.username);
	};

	getUserFromLocalStorage = () => {
		const Username = localStorage.getItem('notConfirmedUser');
		if (Username) {
			const userData = {
				Username,
				Pool: this.userPool,
			};
			return new CognitoUser(userData);
		}
		return undefined;
	};

	removeUserFromLocalStorage = () => {
		localStorage.removeItem('notConfirmedUser');
	};
}

export default new AuthApi();
