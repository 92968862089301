import React, { Component, ErrorInfo } from 'react';
// @TODO add error logger with server API https://aws.amazon.com/ru/kinesis/

class ErrorBoundary extends Component {
    state = {
        hasError: false,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // api post request with error will be send here
        this.setState({
            hasError: true,
        });
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return <p>Something went wrong ;(</p>;
        }
        return children;
    }
}

export default ErrorBoundary;
