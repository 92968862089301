import { baseURL } from '../../../../config/app.config';
import authApi from '../utils/authAPI';

/**
 *
 * @param {string} [method]
 * @param {string} url
 * @param {object} [body]
 * @param {boolean} [authRequired]
 * @returns {Promise<Response>}
 */
export default function ({ method = 'GET', url, body, authRequired }) {
  let requestUrl = url;
  let headers;
  if (url.indexOf('http') === -1) {
    requestUrl = `${baseURL}/${url}`;
  }
  if (authRequired) {
    const idToken = authApi.getIdToken();
    headers = new Headers({
      Authorization: idToken,
    });
  }
  return fetch(requestUrl, { method, headers, body }).then((response) => {
    if (!response.ok) {
      throw new Error(`Something went wrong: request urs is: ${requestUrl}`);
    }
    return response;
  });
}
