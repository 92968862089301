import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';

import AppLayout from './containers/AppLayout';
import { theme } from '../../../theme';
import { globalStyles } from './globalStyles';

import configureStore from './configureStore';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState);
const MOUNT_NODE = document.getElementById('root');

const render = () => {
	return ReactDOM.render(
		<ThemeProvider theme={theme}>
			<Global styles={globalStyles} />
			<Provider store={store}>
				<AppLayout />
			</Provider>
		</ThemeProvider>,
		MOUNT_NODE,
	);
};

if (module.hot) {
	// Hot reloadable React containers and translation json files
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept('./containers/AppLayout', () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		render();
	});
}

render();
