/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { createStructuredSelector } from 'reselect';
import Page from 'ui-lib/src/components/Page';
import Notification from 'ui-lib/src/components/Notification';
import {
	errorMessage,
	loadingTypes,
	getChartsTypesRequest,
	chartTypes,
	getChartRequest,
	loadingChart,
	chartUrl,
	selectedChart
} from '../../ducks/charts.duck';

function Charts({
	errorMessage,
	loadingTypes,
	getChartsTypesRequest,
	chartTypes,
	getChartRequest,
	loadingChart,
	selectedChart,
	chartUrl
}) {
	useEffect(() => {
		getChartsTypesRequest();
	}, [getChartsTypesRequest]);
	const handleChartTypeClick = (chartType) => {
		getChartRequest(chartType);
	};
	return (
			<Page>
					<ButtonsContainer>
					{
						loadingTypes
							? 'loading'
							: chartTypes.map(chart => (
								<button type='button' key={chart.name} onClick={() => handleChartTypeClick(chart.label)}>
									{chart.label}
								</button>
								))
					}
					</ButtonsContainer>
					<ChartContainer>
					{
						loadingChart && 'loading'
					}
					{
						selectedChart && chartUrl && (
							<ChartImg src={chartUrl} alt='chart' />
						)
					}
					</ChartContainer>
			{errorMessage && <Notification type="error" text={errorMessage} />}
			</Page>
	);
}

Charts.propTypes = {
	errorMessage: PropTypes.string,
	loadingTypes: PropTypes.bool,
	getChartsTypesRequest: PropTypes.func,
	getChartRequest: PropTypes.func,
	chartTypes: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		label: PropTypes.string
	})), PropTypes.object]),
	loadingChart: PropTypes.bool,
	chartUrl: PropTypes.string,
	selectedChart: PropTypes.string,
};

Charts.defaultProps = {
	errorMessage: '',
	loadingTypes: false,
	chartTypes: [],
	getChartsTypesRequest: () => {},
	getChartRequest: () => {},
	loadingChart: false,
	chartUrl: undefined,
	selectedChart: undefined
};

const mapStateToProps = createStructuredSelector({
	errorMessage: errorMessage(),
	loadingTypes: loadingTypes(),
	chartTypes: chartTypes(),
	loadingChart: loadingChart(),
	chartUrl: chartUrl(),
	selectedChart: selectedChart(),
});

export default connect(mapStateToProps, {
	getChartsTypesRequest,
	getChartRequest
})(Charts);

/*------------ STYLES ------------*/
const ButtonsContainer = styled.div`
  margin: 20px 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const ChartContainer = styled.div`
	width: 100%;
`;

const ChartImg = styled.img`
	width: 100%;
	height: auto;
`;