import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Page from 'ui-lib/src/components/Page';
import { StatsDots } from 'ui-lib/src';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { loggedIn } from '../ducks/auth.duck';
import Charts from "./Charts";

const LandingPage = ({ isLoggedIn }) => (
  <Page>
			<div className="pageContainer">
				{isLoggedIn ? <Charts /> : (
					<WelcomeContainer>
					<StatsDots color="#1976d2" size="60px" />
					<h1>Welcome to MoneyTracker!</h1>
				</WelcomeContainer>
				)}
			</div>
	</Page>
);

LandingPage.propTypes = {
	isLoggedIn: PropTypes.bool,
};

LandingPage.defaultProps = {
	isLoggedIn: false,
};

const mapStateToProps = createStructuredSelector({
	isLoggedIn: loggedIn(),
});

export default connect(mapStateToProps, null)(LandingPage);

/*------------ STYLES ---------------*/
const WelcomeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20%;
	height: 100%;
	width: 100%;
`;
