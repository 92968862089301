import React from 'react';

const CheckIcon: React.FC = () => (
	<svg
		x="0px"
		y="0px"
		width="16px"
		height="16px"
		viewBox="0 0 32 32"
		xmlSpace="preserve"
	>
		<path d="M28 0h-24c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4v-24c0-2.2-1.8-4-4-4zM14 24.828l-7.414-7.414 2.828-2.828 4.586 4.586 9.586-9.586 2.828 2.828-12.414 12.414z"></path>
	</svg>
);

export default CheckIcon;
