import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { appName } from '../../../../config/app.config';
import * as chartsService from '../utils/chartsService';

export const moduleName = 'charts';
const prefix = `${appName}/${moduleName}`;

// Constants
export const GET_CHART_TYPES = `${prefix}/GET_CHART_TYPES`;
export const GET_CHARTS_TYPES_STARTED = `${prefix}/GET_CHARTS_TYPES_STARTED`;
export const GET_CHARTS_TYPES_SUCCESS = `${prefix}/GET_CHARTS_TYPES_SUCCESS`;
export const GET_CHARTS_TYPES_FAIL = `${prefix}/GET_CHARTS_TYPES_FAIL`;
export const GET_CHART = `${prefix}/GET_CHART`;
export const GET_CHART_STARTED = `${prefix}/GET_CHART_STARTED`;
export const GET_CHART_SUCCESS = `${prefix}/GET_CHART_SUCCESS`;
export const GET_CHART_FAIL = `${prefix}/GET_CHART_FAIL`;

// Selectors
const selectCharts = (state) => state.get(moduleName);

export const errorMessage = () =>
  createSelector(selectCharts, (chartsState) =>
    chartsState.get('errorMessage')
  );

export const loadingTypes = () =>
  createSelector(selectCharts, (chartsState) =>
    chartsState.get('loadingTypes')
  );
export const loadingChart = () =>
  createSelector(selectCharts, (chartsState) =>
    chartsState.get('loadingChart')
  );
export const chartTypes = () =>
  createSelector(selectCharts, (chartsState) => chartsState.get('chartTypes'));
export const selectedChart = () =>
  createSelector(selectCharts, (chartsState) =>
    chartsState.get('selectedChart')
  );
export const chartUrl = () =>
  createSelector(selectCharts, (chartsState) => chartsState.get('chartUrl'));

// Reducer
export const initialState = fromJS({
  loadingTypes: false,
  loadingChart: false,
  chartTypes: [],
  selectedChart: undefined,
  errorMessage: '',
  chartUrl: '',
});

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHARTS_TYPES_STARTED:
      return state.set('loadingTypes', true);
    case GET_CHARTS_TYPES_SUCCESS:
      return state.set('loadingTypes', false).set('chartTypes', payload);
    case GET_CHARTS_TYPES_FAIL:
      return state.set('loadingTypes', false).set('errorMessage', payload);
    case GET_CHART_STARTED:
      return state.set('loadingChart', true).set('selectedChart', payload);
    case GET_CHART_SUCCESS:
      return state.set('loadingChart', false).set('chartUrl', payload);
    case GET_CHART_FAIL:
      return state.set('loadingChart', false).set('errorMessage', payload);
    default:
      return state;
  }
}

// Action creators
/**
 * @returns {{ type: String }}
 */
export const getChartsTypesRequest = () => ({
  type: GET_CHART_TYPES,
});

export const getChartRequest = (payload) => ({
  type: GET_CHART,
  payload,
});

// Sagas
export function* getChartsTypesSaga() {
  yield put({ type: GET_CHARTS_TYPES_STARTED });
  try {
    const result = yield call(chartsService.getChartTypes);
    yield put({
      type: GET_CHARTS_TYPES_SUCCESS,
      payload: result,
    });
  } catch (e) {
    yield put({ type: GET_CHARTS_TYPES_FAIL });
  }
}

export function* getChartsTypes() {
  yield takeEvery(GET_CHART_TYPES, getChartsTypesSaga);
}

export function* getChart() {
  yield takeEvery(GET_CHART, getChartSaga);
}

export function* getChartSaga({ payload }) {
  yield put({ type: GET_CHART_STARTED, payload });
  try {
    const { chartUrl } = yield call(chartsService.getChart, payload);
    yield put({
      type: GET_CHART_SUCCESS,
      payload: chartUrl,
    });
  } catch (e) {
    yield put({ type: GET_CHART_FAIL });
  }
}

export function* chartsRootSaga() {
  yield all([getChartsTypes(), getChart()]);
}
