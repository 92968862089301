export const theme = {
	backgroundPrimary: '#2196f3',
	backgroundPrimaryHover: '#1976d2',
	backgroundSecondary: '#e10050',
	backgroundSecondaryHover: 'rgb(157, 0, 56)',
	errorBackgroundColor: '#efa1a1',
	errorColor: '#e10050',
	colorPrimary: '#424242',
	colorWhite: '#fff',
	boxShadowColor: `rgba(0, 0, 0, 0.2)`,
	button: {
		borderRadius: '4px',
		boxShadow: `0px 1px 5px 0px rgba(0,0,0,0.2),
                0px 2px 2px 0px rgba(0,0,0,0.14), 
                0px 3px 1px -2px rgba(0,0,0,0.12)`,
		padding: '8px 16px',
		margin: '8px',
		default: {
			color: 'rgba(0, 0, 0, 0.87)',
			backgroundColor: '#eee',
			backgroundColorHover: '#d5d5d5',
		},
		primary: {
			color: '#ffffff',
			backgroundColor: '#2196f3',
			backgroundColorHover: '#1976d2',
		},
		secondary: {
			color: '#ffffff',
			backgroundColor: '#e10050',
			backgroundColorHover: 'rgb(157, 0, 56)',
		},
		disabled: {
			color: 'rgba(0, 0, 0, 0.26)',
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
			boxShadow: 'none',
		},
	},
	containerWidth: '1280px',
	mediaQueriesMax: {
		xs: '600px',
		sm: '960px',
		md: '1280px',
		xl: '1920px',
	},
	zIndex: {
		dropdown: 1,
		header: 2,
	},
};
