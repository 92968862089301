// @TODO: add tests
function saveToLocalStorage(fieldName, data) {
  if(typeof localStorage !== 'undefined') {
    if(fieldName && data) {
      localStorage.setItem(fieldName, JSON.stringify(data));
    }
  }
}

function getFromLocalStorage(fieldName) {
  if(typeof localStorage !== 'undefined') {
    const data = localStorage.getItem(fieldName);
    return JSON.parse(data);
  }
}

export {
  saveToLocalStorage,
  getFromLocalStorage,
};
