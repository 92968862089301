export default function throttle(fn, ms) {
	let throttled = false;
	let lastArgs;
	let lastThis;
	function wrapper(...args) {
		if (throttled) {
			lastArgs = args;
			lastThis = this;
			return;
		}
		throttled = true;
		fn.apply(this, args);
		setTimeout(function() {
			throttled = false;
			if (lastArgs) {
				wrapper.apply(lastThis, lastArgs);
				lastArgs = null;
				lastThis = null;
			}
		}, ms);
	}
	return wrapper;
}
