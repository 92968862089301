import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Input from '../Input';
import Button from '../Button';
import Radio from '../RadioComponent';
import Dropdown from '../Dropdown';
import {
	reduceFormInputs,
	changeValue,
	validateForm,
	validateItem,
	removeError,
	isFormValid,
} from '../../utils/authFormUtils/utils';
import Select from '../Select/index';
import Calendar from '../Calendar';
import FormError from '../FormError';
import InputWithSuggestions from '../InputWithSuggestions';
import {
	convertDateTo,
	DatePatterns,
} from '../../../../core/src/utils/date-format';

class Form extends Component {
	static propTypes = {
		inputs: PropTypes.arrayOf(
			PropTypes.shape({
				component: PropTypes.oneOf([
					'input',
					'textarea',
					'select',
					'dropdown',
					'calendar',
					'inputWithSuggestions',
				]),
				type: PropTypes.oneOf(['text', 'email', 'password', 'submit', 'radio']),
				name: PropTypes.string,
				label: PropTypes.string,
				value: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.number,
					PropTypes.instanceOf(Date),
				]),
				validations: PropTypes.arrayOf(
					PropTypes.oneOf(['required', 'email', 'password', 'onlyNumbers']),
				),
				options: PropTypes.arrayOf(
					PropTypes.shape({
						title: PropTypes.string,
						value: PropTypes.string,
					}),
				),
				closeOnClick: PropTypes.bool,
			}),
		),
		formDirection: PropTypes.string,
		onSubmit: PropTypes.func,
		submitBtn: PropTypes.shape({
			text: PropTypes.string,
			disabled: PropTypes.bool,
		}),
		header: PropTypes.string,
		errorMessage: PropTypes.string,
		closeMenu: PropTypes.func,
		headerStyles: PropTypes.shape({}),
	};

	static defaultProps = {
		inputs: [],
		submitBtn: undefined,
		header: '',
		onSubmit: () => {},
		errorMessage: '',
		formDirection: 'column',
		closeMenu: () => {},
	};

	constructor(props) {
		super(props);
		this.state = {
			inputs: reduceFormInputs(props.inputs),
		};
	}

	componentDidMount() {}

	componentWillUnmount() {}

	componentDidUpdate(prevProps) {
		const newInputs = this.props.inputs;
		if (newInputs !== prevProps.inputs) {
			this.setState(prevState => ({
				...prevState,
				inputs: reduceFormInputs(newInputs, prevState.inputs),
			}));
		}
	}

	get inputs() {
		const { inputs } = this.state;
		const errors = errs => {
			const eKeys = Object.keys(errs);
			if (eKeys.length > 0) {
				return eKeys.map(key => <FormError key={key} errorText={errs[key]} />);
			}
			return <FormError />;
		};
		return inputs.reduce((acc, input) => {
			if (input.component === 'inputWithSuggestions') {
				acc.push(
					<Row key={input.name}>
						<InputWithSuggestions
							{...input}
							onSelectValue={this.handleDropClick}
						/>
					</Row>,
				);
				return acc;
			}
			if (input.component === 'calendar') {
				const handleDateChange = date => {
					this.handleCalendarChange(date, input.name);
				};
				const MenuContent = () => (
					<Calendar value={input.value} onChange={handleDateChange} />
				);
				acc.push(
					<Row key={input.name}>
						Select Date:{' '}
						<Dropdown
							{...input}
							title={this.getCalendarTitle(input)}
							render={MenuContent}
						/>
						{errors(input.errors)}
					</Row>,
				);
				return acc;
			}
			if (input.component === 'select') {
				acc.push(
					<Select
						options={input.options}
						handleChange={this.handleChange}
						{...input}
						key={input.name}
					/>,
				);
				return acc;
			}
			if (input.component === 'dropdown') {
				const MenuContent = () => (
					<ul>
						{input.items.map(item => (
							<li
								key={item.id}
								onClick={() => this.handleDropClick(input.name, item.id)}
							>
								{item.name}
							</li>
						))}
					</ul>
				);
				acc.push(
					<Row key={input.name}>
						<Dropdown
							{...input}
							title={this.dropdownCurrentItem(input)}
							render={MenuContent}
						></Dropdown>
						{errors(input.errors)}
					</Row>,
				);
				return acc;
			}
			switch (input.type) {
				case 'text':
				case 'email':
				case 'password':
					acc.push(
						<Row key={input.label}>
							<Input
								{...input}
								onChange={this.handleChange}
								onFocus={this.handleFocus}
								onBlur={this.handleBlur}
								data-test="form-row"
							/>
						</Row>,
					);
					return acc;
				case 'radio':
					acc.push(
						<Row key={input.name}>
							<Radio {...input} onChange={this.handleChange} />
						</Row>,
					);
					return acc;
				case 'submit':
					acc.push(
						<input key={input.value} type="submit" value={input.value} />,
					);
					return acc;
				default:
					return acc;
			}
		}, []);
	}

	get formHeader() {
		const { header, headerStyles } = this.props;
		return header ? (
			<Header headerStyles={headerStyles}>{header}</Header>
		) : null;
	}

	get submit() {
		const { submitBtn } = this.props;
		return submitBtn ? (
			<Button {...submitBtn} type="submit" buttonStyle="primary">
				{submitBtn.text}
			</Button>
		) : (
			undefined
		);
	}

	get errorMessage() {
		const { errorMessage } = this.props;
		return errorMessage ? (
			<ErrorMessage>{errorMessage}</ErrorMessage>
		) : (
			undefined
		);
	}

	resetForm = () => {
		const { inputs } = this.props;
		this.setState({
			inputs: reduceFormInputs(inputs),
		});
	};

	handleSubmit = e => {
		const { inputs } = this.state;
		const { onSubmit, closeMenu } = this.props;
		e.preventDefault();
		const validatedInputs = validateForm(inputs);
		this.setState(prevState => ({
			...prevState,
			inputs: validatedInputs,
		}));
		if (isFormValid(validatedInputs)) {
			const payload = inputs.reduce((acc, { name, value }) => {
				acc[name] = value;
				return acc;
			}, {});
			onSubmit(payload);
			this.resetForm();
			closeMenu();
		}
	};

	handleChange = (name, e) => {
		const { inputs } = this.state;
		const { value } = e.target;

		this.setState({
			inputs: changeValue(inputs, { name }, { value }),
		});
	};

	handleDropClick = (name, value) => {
		const { inputs } = this.state;
		this.setState({
			inputs: changeValue(inputs, { name }, { value }),
		});
	};

	handleFocus = name => {
		const { inputs } = this.state;

		this.setState({
			inputs: removeError(inputs, name),
		});
	};

	handleBlur = name => {
		const { inputs } = this.state;
		const newInputs = inputs.map(input => {
			if (input.name === name) {
				return validateItem(input);
			}
			return input;
		});
		this.setState({
			inputs: newInputs,
		});
	};

	handleCalendarChange = (newDate, inputName) => {
		const { inputs } = this.state;
		this.setState({
			inputs: changeValue(inputs, { name: inputName }, { value: newDate }),
		});
	};

	dropdownCurrentItem = input => {
		const currentInput = input.items.filter(item => item.id === input.value);
		if (currentInput && currentInput.length > 0) {
			return currentInput[0].name;
		}
		return () => <span>{input.title}</span>;
	};
	getCalendarTitle = input => () => (
		<span>
			{input.value
				? convertDateTo({
						date: input.value,
						pattern: input.pattern || DatePatterns['dd/mm/yyyy'],
				  })
				: input.title}
		</span>
	);

	render() {
		const { formDirection, style } = this.props;

		return (
			<FormContainer
				noValidate
				data-test="form-element"
				onSubmit={this.handleSubmit}
				formDirection={formDirection}
				style={style && style.container}
				autoComplete="off"
			>
				{this.formHeader}
				{this.inputs}
				{this.submit}
				{this.errorMessage}
			</FormContainer>
		);
	}
}

export default Form;

/*--------------------- STYLES -------------------------*/
const FormContainer = styled('form')`
	display: flex;
	flex-direction: ${({ formDirection }) => formDirection};
	margin: ${({ style }) => (style && style.margin) || '0 auto'};
	max-width: 550px;
	box-sizing: border-box;
	padding: 10px;
	align-items: ${({ formDirection }) =>
		formDirection === 'row' ? 'baseline' : ''};
`;

const Header = styled('h5')`
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colorPrimary};
	${({ headerStyles }) => ({ ...headerStyles })};
`;

const ErrorMessage = styled('span')`
	font-size: 14px;
	color: ${({ theme }) => theme.backgroundSecondary};
	line-height: 1;
`;

const Row = styled.div``;
