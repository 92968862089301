import React from 'react';
import styled from '@emotion/styled';
import ArrowRight from '../icons/ArrowRight';
import ArrowLeft from '../icons/ArrowLeft';

const monthList = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

interface MonthsProps {
	month: number;
	year: number;
	onChange?: (month: number, year?: number) => void;
}

const Months: React.FC<MonthsProps> = ({
	month,
	year,
	onChange = () => {},
}) => {
	const handlePrevClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (month > 0) {
			onChange(month - 1);
		} else {
			onChange(monthList.length - 1, year - 1);
		}
	};

	const handleNextClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		if (month < monthList.length - 1) {
			onChange(month + 1);
		} else {
			onChange(0, year + 1);
		}
	};

	if (month === undefined || year === undefined) {
		return null;
	}

	return (
		<Container>
			<Year>{year}</Year>
			<MonthBlock>
				<Button onClick={handlePrevClick} type="button">
					<ArrowLeft />
				</Button>
				{monthList[month]}
				<Button onClick={handleNextClick} type="button">
					<ArrowRight />
				</Button>
			</MonthBlock>
		</Container>
	);
};

export default Months;

/*----------------- Styles -----------------*/
const Container = styled.div`
	border-bottom: 1px solid #eee;
`;
const Year = styled.div`
	text-align: center;
	font-weight: bold;
	padding: 5px 0;
`;
const MonthBlock = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Button = styled.button`
	border: none;
	background: none;
	cursor: pointer;
`;
