export function reduceFormInputs(inputs, prevInputs) {
	return inputs.reduce((acc, input) => {
		if (input.type !== 'submit') {
			if (input.validations) {
				let errorsObj = {};
				input.validations.forEach(key => {
					errorsObj[key] = undefined;
				});
				input = {
					...input,
					errors: errorsObj,
				};
			}
			if (prevInputs && input.type === 'text') {
				input.value = prevInputs.find(inp => inp.name === input.name).value;
			}
		}
		acc.push(input);
		return acc;
	}, []);
}

export function getFilteredItems(items, searchObj) {
	let keys = Object.keys(searchObj);
	return items.filter(item => keys.every(key => searchObj[key] === item[key]));
}

export function changeValue(items, searchObj, newKeyValPair) {
	let filteredItem = getFilteredItems(items, searchObj);
	if (filteredItem.length > 1) {
		throw new Error(
			`filtered item is not unique, there are ${filteredItem.length} items`,
		);
	}
	if (filteredItem[0] && filteredItem[0].validations.includes('onlyNumbers')) {
		const { value } = newKeyValPair;
		if (isNaN(value)) {
			const parsed = parseFloat(value);
			if (isNaN(parsed)) {
				newKeyValPair.value = '';
			} else {
				newKeyValPair.value = parsed;
			}
		}
	}
	let keys = Object.keys(searchObj);
	return items.map(item => {
		if (keys.every(key => searchObj[key] === item[key])) {
			item = {
				...item,
				...newKeyValPair,
				errors: {},
			};
		}
		return item;
	});
}

export function validateEmail(item) {
	if (item.validations.includes('email') && item.value.length > 0) {
		let errors = item.errors;
		const pattern = /^[\w.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
		if (!pattern.test(item.value)) {
			errors = {
				...item.errors,
				email: `Wrong E-mail format`,
			};
			item.errors = errors;
		}
	}
	return item;
}

export function validatePasswordLength(item) {
	if (item.validations.includes('password') && item.value.length > 0) {
		let errors = item.errors;
		if (item.value.length <= 5) {
			errors = {
				...item.errors,
				password: `Password is too short`,
			};
			item.errors = errors;
		}
	}
	return item;
}

export function validatePasswordHasLetters(item) {
	if (item.validations.includes('password') && item.value.length > 0) {
		let errors = item.errors;
		if (!/[a-zA-Z]/.test(item.value)) {
			errors = {
				...item.errors,
				password: `Password should have at least one letter`,
			};
			item.errors = errors;
		}
	}
	return item;
}

export function validateRequired(item) {
	if (!item.value && item.validations.includes('required')) {
		let errors = item.errors;
		errors = {
			...item.errors,
			required: `${item.label} is required`,
		};
		item = {
			...item,
			errors,
		};
	}
	return item;
}

export function compose(fns) {
	return arg => {
		return fns.reduce((acc, fn) => fn(acc), arg);
	};
}

export function validateItem(item) {
	return item.type !== 'submit'
		? compose([
				validateRequired,
				validateEmail,
				validatePasswordHasLetters,
				validatePasswordLength,
		  ])(item)
		: item;
}

export function validateForm(items) {
	return items.map(validateItem);
}

export function removeError(items, name) {
	return items.map(item => {
		if (item.name === name) {
			item = { ...item, errors: {} };
		}
		return item;
	});
}

export function isFormValid(items) {
	return items.reduce((acc, item) => {
		if (item.errors && acc) {
			acc = isFieldValid(item.errors);
		}
		return acc;
	}, true);
}

export function isFieldValid(errors) {
	const keys = Object.keys(errors);
	for (let key of keys) {
		if (errors[key]) {
			return false;
		}
	}
	return true;
}
