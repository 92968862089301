import React from "react";
import PropTypes from "prop-types";

const Page = ({ children }) => <div className="page">{children}</div>;

Page.propTypes = {
	children: PropTypes.node,
};

Page.defaultProps = {
	children: undefined,
};

export default Page;
