import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { LOGOUT_USER_SUCCESS } from './auth.duck';
import { appName } from '../../../../config/app.config';
import * as sectionService from '../utils/sectionService';

export const moduleName = 'sections';
const prefix = `${appName}/${moduleName}`;

// Selectors
const selectSections = state => state.get(moduleName);
export const loading = () =>
	createSelector(selectSections, state => state.get('loading'));
export const sections = () =>
	createSelector(selectSections, state => state.get('sections'));
export const allSections = () =>
	createSelector(selectSections, state => state.get('allSections'));
export const errorMessage = () =>
	createSelector(selectSections, state => state.get('errorMessage'));
export const filters = () =>
	createSelector(selectSections, state => ({
		pageSize: state.get('pageSize'),
		type: state.get('type'),
	}));
export const pageSize = () =>
	createSelector(selectSections, state => state.get('pageSize'));
export const nextPageToken = () =>
	createSelector(selectSections, state => state.get('nextPageToken'));
export const isLoadingMore = () =>
	createSelector(selectSections, state => state.get('isLoadingMore'));
export const isAddSectionInProgress = () =>
	createSelector(selectSections, state => state.get('isAddSectionInProgress'));

// Constants
export const GET_SECTIONS_STARTED = `${prefix}/GET_SECTIONS_STARTED`;
export const GET_ALL_SECTIONS_STARTED = `${prefix}/GET_ALL_SECTIONS_STARTED`;
export const GET_SECTIONS_SUCCESS = `${prefix}/GET_SECTIONS_SUCCESS`;
export const GET_ALL_SECTIONS_SUCCESS = `${prefix}/GET_ALL_SECTIONS_SUCCESS`;
export const GET_SECTIONS_FAIL = `${prefix}/GET_SECTIONS_FAIL`;
export const GET_ALL_SECTIONS_FAIL = `${prefix}/GET_ALL_SECTIONS_FAIL`;
export const GET_SECTIONS = `${prefix}/GET_SECTIONS`;
export const ADD_SECTION = `${prefix}/ADD_SECTION`;
export const ADD_SECTION_STARTED = `${prefix}/ADD_SECTION_STARTED`;
export const ADD_SECTION_SUCCESS = `${prefix}/ADD_SECTION_SUCCESS`;
export const ADD_SECTION_FAIL = `${prefix}/ADD_SECTION_FAIL`;
export const DELETE_SECTION = `${prefix}/DELETE_SECTION`;
export const DELETE_SECTION_STARTED = `${prefix}/DELETE_SECTION_STARTED`;
export const DELETE_SECTION_SUCCESS = `${prefix}/DELETE_SECTION_SUCCESS`;
export const DELETE_SECTION_FAIL = `${prefix}/DELETE_SECTION_FAIL`;
export const PAGESIZE_CHANGED = `${prefix}/PAGESIZE_CHANGED`;
export const LOAD_MORE = `${prefix}/LOAD_MORE`;
export const LOAD_MORE_SUCCESS = `${prefix}/LOAD_MORE_SUCCESS`;
export const LOAD_MORE_FAIL = `${prefix}/LOAD_MODE_FAIL`;

// Reducer
export const initialState = fromJS({
	loading: false,
	sections: [],
	allSections: [],
	errorMessage: '',
	nextPageToken: '',
	pageSize: '20',
	type: '',
	isLoadingMore: false,
	isAddSectionInProgress: false,
	isDeleteSectionInProgress: false,
});

/**
 *
 * @param {Object} state
 * @param {boolean} state.loading
 * @param {Array} state.sections
 * @param {string} state.errorMessage
 * @param {Object} action
 * @param {string} action.type
 * @param {array|string|object|undefined} [action.payload]
 * @returns {Object}
 */
export default function(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_SECTIONS_STARTED:
			return state.set('loading', true);
		case ADD_SECTION_STARTED:
			return state.set('isAddSectionInProgress', true);
		case DELETE_SECTION_STARTED:
			return state.set('isDeleteSectionInProgress', true);
		case GET_SECTIONS_SUCCESS:
			return state
				.set('loading', false)
				.set('sections', payload.result)
				.set('nextPageToken', payload.nextPageToken);
		case GET_ALL_SECTIONS_SUCCESS:
			return state.set('allSections', payload.result);
		case GET_SECTIONS_FAIL:
		case GET_ALL_SECTIONS_FAIL:
		case LOAD_MORE_FAIL:
			return state.set('errorMessage', payload).set('isLoadingMore', false);
		case ADD_SECTION_FAIL:
			return state
				.set('isAddSectionInProgress', false)
				.set('errorMessage', payload);
		case DELETE_SECTION_FAIL:
			return state
				.set('isDeleteSectionInProgress', false)
				.set('errorMessage', payload);
		case ADD_SECTION_SUCCESS:
			return state.set('isAddSectionInProgress', false);
		case DELETE_SECTION_SUCCESS:
			return state
				.set('isDeleteSectionInProgress', false)
				.set('sections', payload);
		case LOGOUT_USER_SUCCESS:
			return fromJS({});
		case PAGESIZE_CHANGED:
			return state.set('pageSize', payload);
		case LOAD_MORE:
			return state.set('isLoadingMore', true);
		case LOAD_MORE_SUCCESS:
			const { result, nextPageToken } = payload;
			return state
				.set('sections', state.get('sections').concat(result))
				.set('nextPageToken', nextPageToken)
				.set('isLoadingMore', false);
		default:
			return state;
	}
}

// Action creators
export function fetchSectionsRequest(payload) {
	return {
		type: GET_SECTIONS,
		payload,
	};
}

export function fetchAllSectionsRequest() {
	return {
		type: GET_SECTIONS,
		payload: 'all',
	};
}

export function fetchSectionsStarted() {
	return {
		type: GET_SECTIONS_STARTED,
	};
}

export function fetchAllSectionsStarted() {
	return {
		type: GET_ALL_SECTIONS_STARTED,
	};
}

export function fetchSectionsSuccess(payload) {
	return {
		type: GET_SECTIONS_SUCCESS,
		payload,
	};
}

export function fetchAllSectionsSuccess(payload) {
	return {
		type: GET_ALL_SECTIONS_SUCCESS,
		payload,
	};
}

export function fetchSectionsFail(payload) {
	return {
		type: GET_SECTIONS_FAIL,
		payload,
	};
}

export function fetchAllSectionsFail(payload) {
	return {
		type: GET_ALL_SECTIONS_FAIL,
		payload,
	};
}

export function addSectionRequest(payload) {
	return {
		type: ADD_SECTION,
		payload,
	};
}

export const addSectionStarted = () => ({ type: ADD_SECTION_STARTED });
export const addSectionSuccess = payload => ({
	type: ADD_SECTION_SUCCESS,
	payload,
});
export const addSectionFail = payload => ({ type: ADD_SECTION_FAIL, payload });

export const deleteSectionRequest = payload => ({
	type: DELETE_SECTION,
	payload,
});

export const deleteSectionStarted = () => ({ type: DELETE_SECTION_STARTED });
export const deleteSectionSuccess = payload => ({
	type: DELETE_SECTION_SUCCESS,
	payload,
});
export const deleteSectionFail = payload => ({
	type: DELETE_SECTION_FAIL,
	payload,
});
export const pageSizeChanged = payload => ({
	type: PAGESIZE_CHANGED,
	payload,
});
export const loadMoreSectionsRequest = () => ({
	type: LOAD_MORE,
});
export const loadMoreSuccess = payload => ({
	type: LOAD_MORE_SUCCESS,
	payload,
});
export const loadMoreFail = payload => ({
	type: LOAD_MORE_FAIL,
	payload,
});
// Sagas
export function* getSectionsSaga({ payload } = {}) {
	if (payload === 'all') {
		yield put(fetchAllSectionsStarted());
	} else {
		yield put(fetchSectionsStarted());
	}
	if (!payload) {
		const currentSections = yield select(sections());
		if (currentSections && currentSections.size) {
			console.error(
				'getSectionsSaga was called without payload and there are sections in storage',
			);
			return;
		}
	}
	if (payload === 'all') {
		try {
			let { result } = yield call(sectionService.getAllSections);
			// result = null returns if there are no sections
			if (!result) {
				result = [];
			}
			yield put(fetchAllSectionsSuccess({ result }));
		} catch (e) {
			yield put(fetchAllSectionsFail(e.message));
			console.error(`getSectionsSaga with payload='all' error: ${e}`);
		}
	} else
		try {
			let query = yield select(filters());
			const fetchQuery = { ...query, ...payload };
			let { result, nextPageToken } = yield call(
				sectionService.getSections,
				fetchQuery,
			);
			// result = null returns if there are no sections
			if (!result) {
				result = [];
			}
			yield put(fetchSectionsSuccess({ result, nextPageToken }));
		} catch (e) {
			yield put(fetchSectionsFail(e.message));
			console.error(
				`getSectionsSaga with payload=${JSON.stringify(payload)} error: ${e}`,
			);
		}
}

export function* addSectionSaga({ payload } = {}) {
	yield put(addSectionStarted());
	try {
		const newSection = yield call(sectionService.addSection, payload);
		yield put(addSectionSuccess(newSection));
		const query = yield select(filters());
		yield* getSectionsSaga({ payload: query });
	} catch (e) {
		yield put(addSectionFail(e.message));
		console.error(
			`addSectionsSaga with payload=${JSON.stringify(payload)} error: ${e}`,
		);
	}
}

export function* deleteSectionSaga({ payload } = {}) {
	yield put(deleteSectionStarted());
	try {
		yield call(sectionService.deleteSection, payload);
		const oldSections = yield select(sections());
		const newSections = oldSections.filter(section => section.id !== payload);
		yield put(deleteSectionSuccess(newSections));
	} catch (e) {
		yield put(deleteSectionFail(e));
		console.error(
			`deleteSectionSaga with payload=${JSON.stringify(payload)} error: ${e}`,
		);
	}
}

export function* pageSizeChangedSaga({ payload }) {
	yield* getSectionsSaga({ payload: { pageSize: payload } });
}

export function* loadMoreSaga() {
	const token = yield select(nextPageToken());
	if (token) {
		try {
			let { result, nextPageToken } = yield call(sectionService.getSections, {
				nextPageToken: token,
			});
			// result = null returns if there are no sections
			if (!result) {
				result = [];
			}
			yield put(loadMoreSuccess({ result, nextPageToken }));
		} catch (e) {
			yield put(loadMoreFail(e.message));
			console.error(`loadMoreSaga error: ${e}`);
		}
	}
}

export function* handlePageSizeChange() {
	yield takeEvery(PAGESIZE_CHANGED, pageSizeChangedSaga);
}

export function* getSections() {
	yield takeEvery(GET_SECTIONS, getSectionsSaga);
}

export function* addSection() {
	yield takeEvery(ADD_SECTION, addSectionSaga);
}

export function* deleteSection() {
	yield takeEvery(DELETE_SECTION, deleteSectionSaga);
}

export function* loadMore() {
	yield takeEvery(LOAD_MORE, loadMoreSaga);
}

export function* sectionsRootSaga() {
	yield all([
		getSections(),
		addSection(),
		deleteSection(),
		handlePageSizeChange(),
		loadMore(),
	]);
}
