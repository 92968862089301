import { getBy } from './userRequests';
import { urlBases } from './constants';

/**
 *
 * @returns {undefined | Promise<Response>}
 */
const getChartTypes = () => getBy(urlBases.charts, 'types');

/**
 * @param {string} type
 * @returns {undefined | Promise<Response>}
 */
const getChart = (type) => getBy(urlBases.charts, type);

export { getChartTypes, getChart };
