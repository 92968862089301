import styled from '@emotion/styled';
import { StyledButtonProps } from './types';

const ButtonContainer = styled('button')`
	background: ${({
		disabled,
		theme: { button },
		buttonStyle,
	}: StyledButtonProps) =>
		disabled
			? button.disabled.backgroundColor
			: button[buttonStyle].backgroundColor};
	border-radius: ${({ theme: { button } }) => button.borderRadius};
	border: none;
	box-shadow: ${({ disabled, theme: { button } }) =>
		disabled ? button.disabled.boxShadow : button.boxShadow};
	padding: ${({ theme: { button } }) => button.padding};
	margin: ${({ theme: { button } }) => button.margin};
	outline: none;
	font-size: 16px;
	text-transform: uppercase;
	color: ${({ disabled, theme: { button }, buttonStyle }) =>
		disabled ? button.disabled.color : button[buttonStyle].color};
	&:hover,
	&:active {
		background: ${({ disabled, theme: { button }, buttonStyle }) =>
			disabled
				? button.disabled.backgroundColor
				: button[buttonStyle].backgroundColorHover};
		cursor: pointer;
	}
`;

export default ButtonContainer;
