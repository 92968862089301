import React from 'react';
import { getWeekPeriod, getMonthPeriod } from '../../utils/date-utils';

enum periods {
	notSelected = 'not selected',
	week = 'this week',
	month = 'this month',
	pickPeriod = 'select a period...',
}

const isValidPeriod = (value: string): value is keyof typeof periods =>
	value in periods;

interface DateMenuProps {
	closeMenu: () => void;
	onChange: ({ from, to }: { from: Date; to: Date }) => void;
	handlePickDateClick: (closeMenu: () => void) => void;
}

const DateMenu: React.FC<DateMenuProps> = ({
	closeMenu,
	onChange,
	handlePickDateClick,
}) => {
	const handleSelect = (period: string, e: React.MouseEvent) => {
		switch (period) {
			case periods.notSelected:
				onChange({ from: undefined, to: undefined });
				closeMenu();
				return;
			case periods.week:
				onChange(getWeekPeriod());
				closeMenu();
				return;
			case periods.month:
				onChange(getMonthPeriod());
				closeMenu();
				return;
			case periods.pickPeriod:
				e.nativeEvent.stopImmediatePropagation();
				handlePickDateClick(closeMenu);
				return;
		}
	};

	const periodKeys = Object.keys(periods);

	return (
		<ul>
			{periodKeys.map(
				(period: string) =>
					isValidPeriod(period) && (
						<li key={period} onClick={e => handleSelect(periods[period], e)}>
							{periods[period]}
						</li>
					),
			)}
		</ul>
	);
};

export default DateMenu;
