import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';

const notificationEl = document.getElementById('notification-root');

class Notification extends Component {
	notificationRoot = document.createElement('div');
	state = {
		show: true,
	};
	componentDidMount() {
		if (notificationEl) {
			notificationEl.appendChild(this.notificationRoot);
		}
		setTimeout(() => {
			this.setState({
				show: false,
			});
		}, 3000);
	}

	componentWillUnmount() {
		if (notificationEl) {
			notificationEl.removeChild(this.notificationRoot);
		}
	}
	render() {
		const { type, text } = this.props;
		const { show } = this.state;
		if (!show) {
			return null;
		}
		const Portal = <NotificationBlock type={type}>{text}</NotificationBlock>;
		return createPortal(Portal, this.notificationRoot);
	}
}

export default Notification;

// ------------------ STYLES ----------------- //
const NotificationBlock = styled.div`
	position: fixed;
	top: 90px;
	right: 20px;
	max-width: 600px;
	min-width: 280px;
	background: #fff;
	box-shadow: 0 10px 10px
		${({ type }) =>
			type === 'error' ? 'rgba(200, 100, 100, 1)' : 'rgba(145, 199, 132, 1)'};
	color: ${({ type }) =>
		type === 'error' ? 'rgba(200, 16, 16, 1)' : 'rgba(45, 138, 32, 1)'};
	padding: 30px 40px;
	border-radius: 5px;
	transition: opacity 1s;
`;
