import React from 'react';

type chevroneType = {
	up?: boolean;
};

const Chevrone: React.FC<chevroneType> = ({ up }) => (
	<svg
		x="0px"
		y="0px"
		width="20px"
		height="20px"
		viewBox="0 0 24 24"
		xmlSpace="preserve"
		style={{
			transform: up ? 'rotate(180deg)' : '',
		}}
	>
		<path d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
	</svg>
);

export default Chevrone;
