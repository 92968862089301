import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Menu from '../Dropdown/Menu';
import InputField from '../InputField';
import throttle from '../../utils/throttle';

const InputWithSuggestionsComponent = ({
    options,
    onSelectValue,
    value,
    name,
    label,
    errors,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(options);
    useEffect(() => {
        setSuggestions(options);
    }, [options]);
    const [searchValue, setSearchValue] = useState('');
    useEffect(() => {
        const selectedOption = options.find((opt) => opt.value === value);
        setSearchValue((selectedOption && selectedOption.title) || '');
    }, [value]);
    const handleInputChange = (name, e) => {
        const newValue = e.target.value;
        setSearchValue(newValue);
        delayedSuggestions(newValue, options);
    };
    const delayedSuggestions = useRef(
        throttle((newVal, options) => {
            const filteredOptions = options.filter((option) =>
                option.title.toLowerCase().includes(newVal.toLowerCase())
            );
            setSuggestions(filteredOptions);
        }, 1000)
    ).current;
    const openMenu = () => {
        setIsOpen(true);
    };
    const closeMenu = () => {
        setIsOpen(false);
    };
    const handleMenuClick = (e) => {
        if (isOpen) {
            e.nativeEvent.stopImmediatePropagation();
            setIsOpen(false);
        }
    };
    const handleBlur = () => {
        const selectedOption = suggestions.find(
            (opt) => opt.title === searchValue
        );
        if (!selectedOption) {
            onSelectValue(name);
            setSearchValue('');
        }
    };
    const getChildren = () =>
        suggestions.length > 0 ? (
            <div style={{ padding: '10px', overflow: 'hidden' }}>
                <MenuList>
                    {suggestions.map((suggestion) => (
                        <MenuItems
                            key={suggestion.value}
                            onClick={() =>
                                onSelectValue(name, suggestion.value)
                            }
                        >
                            {suggestion.title}
                        </MenuItems>
                    ))}
                </MenuList>
            </div>
        ) : (
            <span>no suggestions</span>
        );

    const inputStyles = {
        margin: '4px 0',
    };

    return (
        <Container>
            <InputField
                type="text"
                label={label}
                onChange={handleInputChange}
                onFocus={openMenu}
                onBlur={handleBlur}
                value={searchValue}
                name={name}
                errors={errors}
                styles={inputStyles}
            />
            {isOpen && (
                <Menu
                    onClickOutside={closeMenu}
                    handleMenuClick={handleMenuClick}
                    style={{ top: '56px' }}
                >
                    {getChildren()}
                </Menu>
            )}
        </Container>
    );
};

export default InputWithSuggestionsComponent;
/*------------ STYLES ------------*/
const Container = styled.div`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
`;
const MenuList = styled.ul`
    padding: 10px;
`;
const MenuItems = styled.li`
    padding: 5px 20px !important;
`;
