import React, { useState, Fragment } from 'react';
import styled from '@emotion/styled';
import { fillRowsInCalendar } from '../../utils/date-utils';

const daysList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

enum dayMonth {
	cur = 'cur',
	prev = 'prev',
	next = 'next',
}

interface DayProps {
	month: dayMonth;
	day: number;
}
const currentMonth = (day: DayProps, date: Date) =>
	day.month === 'cur'
		? date.getMonth()
		: day.month === 'prev'
		? date.getMonth() - 1
		: date.getMonth() + 1;

const DaysRow: React.FC = () => (
	<Row>
		{daysList.map(day => (
			<Item key={day}>{day}</Item>
		))}
	</Row>
);

interface RowsProps {
	date: Date;
	onChange: (day: DayProps) => void;
	startDate: Date;
	endDate: Date;
}

const Rows: React.FC<RowsProps> = ({ date, onChange, startDate, endDate }) => {
	const rows = fillRowsInCalendar(date);
	const isSelected = (day: DayProps): boolean => {
		const currentTime = new Date(
			date.getFullYear(),
			currentMonth(day, date),
			day.day,
		).getTime();

		if (startDate && endDate) {
			if (
				currentTime >= startDate.getTime() &&
				currentTime <= endDate.getTime()
			) {
				return true;
			}
		} else if (startDate) {
			if (startDate.getTime() === currentTime) {
				return true;
			}
		}
		return false;
	};
	return (
		<Fragment>
			{rows.map<React.ReactElement>((row, index) => (
				<Row key={index}>
					{row.map<React.ReactElement>(day => (
						<Day
							key={`${day.day}-${day.month}`}
							day={day}
							onChange={onChange}
							isSelected={isSelected(day)}
						/>
					))}
				</Row>
			))}
		</Fragment>
	);
};

interface DayComponentProps {
	day: DayProps;
	onChange: (day: DayProps) => void;
	isSelected: boolean;
}

const Day: React.FC<DayComponentProps> = ({ day, onChange, isSelected }) => {
	const handleClick = () => {
		onChange(day);
	};
	return (
		<Item onClick={handleClick} isSelected={isSelected}>
			{day.day}
		</Item>
	);
};

interface RangeProps {
	start: Date;
	end: Date;
}

interface DaysComponentProps {
	date: Date;
	onChange: (value: RangeProps) => void;
	value: RangeProps;
}

const Days: React.FC<DaysComponentProps> = ({ date, onChange, value }) => {
	const [startDate, setStartDate] = useState<Date | undefined>(
		value && value.start,
	);
	const [endDate, setEndDate] = useState<Date | undefined>(value && value.end);

	const handleChange = (day: DayProps) => {
		const clickedDate = new Date(
			date.getFullYear(),
			currentMonth(day, date),
			day.day,
		);
		if (!startDate || (startDate && endDate)) {
			setStartDate(clickedDate);
			setEndDate(undefined);
			return;
		} else if (startDate.getTime() > clickedDate.getTime()) {
			setEndDate(startDate);
			setStartDate(clickedDate);
			onChange({
				start: clickedDate,
				end: startDate,
			});
		} else {
			setEndDate(clickedDate);
			onChange({
				start: startDate,
				end: clickedDate,
			});
		}
	};

	return (
		<div>
			<DaysRow />
			<Rows
				date={date}
				onChange={handleChange}
				startDate={startDate}
				endDate={endDate}
			/>
		</div>
	);
};

export default Days;

/*----------------- Styles -----------------*/
const Row = styled.div`
	display: flex;
	flex-wrap: nowrap;
`;
const Item = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: ${({ isSelected }: { isSelected?: boolean }) =>
		isSelected ? 'rgba(33, 43, 145, 0.3)' : ''};
`;
