import React, { Suspense, lazy } from 'react';
import { Router } from '@reach/router';
import styled from '@emotion/styled';
import LoaderIndicator from 'ui-lib/src/components/loadingIndicator';
import PrivateRoute from './PrivateRoute';
import UnauthorizedRoute from './UnauthorizedRoute';
import LandingPage from '../../components/LandingPage';
import NotFound from '../../components/NotFound';

const StyledRouter = styled(Router)`
	display: flex;
	flex-direction: column;
	flex: auto;
	margin: 60px 0 20px 0;
`;

const LoginPage = lazy(() => import('../../components/LoginPage'));
const RegisterPage = lazy(() => import('../../components/RegisterPage'));
const ConfirmPage = lazy(() =>
	import('../../components/ConfirmRegistrationPage'),
);
const Sections = lazy(() => import('../../components/Sections'));
const Categories = lazy(() => import('../../components/Categories'));
const Entries = lazy(() => import('../../components/Entries'));

const AppRouter = () => (
	<Suspense fallback={<LoaderIndicator />}>
		<StyledRouter primary={false}>
			<LandingPage path="/" />
			<UnauthorizedRoute component={LoginPage} path="login" />
			<UnauthorizedRoute component={RegisterPage} path="register" />
			<UnauthorizedRoute component={ConfirmPage} path="registerConfirm" />
			<PrivateRoute component={Sections} path="sections" />
			<PrivateRoute component={Categories} path="categories" />
			<PrivateRoute component={Entries} path="entries" />
			<NotFound default />
		</StyledRouter>
	</Suspense>
);

export default AppRouter;
