import React, { useState } from 'react';
import styled from '@emotion/styled';

import YearMonth from './YearMonth';
import Days from './Days';
import DaysRange from './DaysRange';

enum dayMonth {
	cur = 'cur',
	prev = 'prev',
	next = 'next',
}

interface DayProps {
	month: dayMonth;
	day: number;
}

interface CalendarProps {
	value:
		| {
				start: Date;
				end: Date;
		  }
		| Date;
	onChange?: (d: Date) => void;
	range: boolean;
	onRangeChage: ({ start, end }: { start: Date; end: Date }) => void;
}

const Calendar: React.FC<CalendarProps> = ({
	value,
	onChange,
	range,
	onRangeChage,
}) => {
	// value should be date object
	const getInitialValue = (): Date => {
		if (!range && value instanceof Date) {
			return value || new Date();
		}
		if (!(value instanceof Date)) {
			return (value && value.start) || new Date();
		}
		return new Date();
	};

	const [currentDate, setCurrentDate] = useState<Date>(getInitialValue());
	const getYear = (): number => currentDate.getFullYear();
	const getMonth = (): number => currentDate.getMonth();
	const setNewMonthAndYear = (newMonth: number, newYear: number): void => {
		if (newYear) {
			setCurrentDate(new Date(newYear, newMonth, 1));
		} else {
			setCurrentDate(new Date(getYear(), newMonth, 1));
		}
	};
	const handleDayClick = (day: DayProps): void => {
		const month =
			day.month === 'cur'
				? getMonth()
				: day.month === 'prev'
				? getMonth() - 1
				: getMonth() + 1;
		onChange(new Date(getYear(), month, day.day));
	};
	return (
		<CalendarContainer>
			<YearMonth
				month={getMonth()}
				year={getYear()}
				onChange={setNewMonthAndYear}
			/>
			{range && !(value instanceof Date) && (
				<DaysRange date={currentDate} onChange={onRangeChage} value={value} />
			)}
			{value instanceof Date && (
				<Days date={currentDate} onChange={handleDayClick} value={value} />
			)}
		</CalendarContainer>
	);
};

export default Calendar;

/*----------------- Styles -----------------*/
const CalendarContainer = styled.div`
	border: 1px solid #eee;
	padding: 5px;
	display: inline-block;
`;
