import React from 'react';

const Close: React.FC = () => (
	<svg
		x="0px"
		y="0px"
		width="20px"
		height="20px"
		viewBox="0 0 20 20"
		xmlSpace="preserve"
	>
		<path d="M10 8.586l-7.071-7.071-1.414 1.414 7.071 7.071-7.071 7.071 1.414 1.414 7.071-7.071 7.071 7.071 1.414-1.414-7.071-7.071 7.071-7.071-1.414-1.414-7.071 7.071z"></path>
	</svg>
);

export default Close;
