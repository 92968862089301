import { getWithQuery, addRequest, getBy, deleteBy } from './userRequests';
import { urlBases } from './constants';

/**
 * @param {Object} body
 * @param {string} body.name
 * @param {string} body.sectionId
 * @returns {undefined | Object}
 */
function addCategory(body) {
	const { name, sectionId } = body;
	if (!name || !sectionId) {
		return undefined;
	}
	return addRequest(urlBases.categories, body);
}

/**
 *
 * @param {Object} [query]
 * @param {number} [query.pageSize]
 * @param {string} [query.nextPageToken]
 * @param {string} [query.sectionId]
 * @returns {Array}
 */
const getCategories = query => getWithQuery(urlBases.categories, query);

/**
 * @returns {Array}
 */
const getAllCategories = () =>
	getWithQuery(urlBases.categories, { pageSize: 1000 });

/**
 *
 * @param {string} id
 * @returns {undefined | Promise<Response>}
 */
const getCategory = id => getBy(urlBases.categories, id);

/**
 *
 * @param {string} id
 * @returns {undefined | Promise<Response>}
 */
const deleteCategory = id => deleteBy(urlBases.categories, id);

export {
	addCategory,
	getCategories,
	getCategory,
	deleteCategory,
	getAllCategories,
};
