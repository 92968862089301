const SERVER_ERROR_MESSAGES = {
	InvalidParameterException: 'Password, Email or Username fields are invalid.',
	UsernameExistsException: 'An account with the given email already exists.',
	UserNotConfirmedException: 'User is not confirmed.',
};
const INVALID_PARAMETER_EXEPTION = 'InvalidParameterException';
const USERNAME_EXISTS_EXEPTION = 'UsernameExistsException';
const USER_NOT_CONFIRMED_EXEPTION = 'UserNotConfirmedException';
const POOL_DATA = 'poolData';

export {
	SERVER_ERROR_MESSAGES,
	INVALID_PARAMETER_EXEPTION,
	USERNAME_EXISTS_EXEPTION,
	USER_NOT_CONFIRMED_EXEPTION,
	POOL_DATA,
};

export const BREAKPOINT_MOBILE = 425;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_LAPTOP = 1440;
