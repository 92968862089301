import React from 'react';
import styled from '@emotion/styled';
import { CheckIcon } from 'ui-lib/src';
import { UncheckIcon } from 'ui-lib/src';

const RadioComponent = ({ name, items, value, onChange, id }) => {
	const handleChange = e => {
		onChange(name, e);
	};
	return (
		<RadioContainer>
			{items.map(item => {
				const uid = `${item.value}${id}`;
				return (
					<ItemContainer key={uid}>
						<LabelChecked htmlFor={uid}>
							{item.title}
							{item.value === value ? <CheckIcon /> : <UncheckIcon />}
						</LabelChecked>
						<input
							type="radio"
							id={uid}
							name={name}
							value={item.value}
							checked={item.value === value}
							onChange={handleChange}
						/>
					</ItemContainer>
				);
			})}
		</RadioContainer>
	);
};

export default RadioComponent;

/*------------ STYLES ------------*/
const RadioContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
`;
const ItemContainer = styled.div`
	display: flex;
	& input {
		display: none;
	}
`;
const LabelChecked = styled.label`
	display: flex;
	padding: 10px 0;
	margin-right: 10px;
	align-items: center;
	cursor: pointer;
	& svg {
		margin-left: 10px;
	}
`;
