export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const urlBases = {
  categories: 'categories',
  sections: 'sections',
  entries: 'entries',
  charts: 'charts',
};
export const itemsPerPage = ['20', '50', '100'];
