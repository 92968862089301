import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import DateMenu from './DateMenu';
import { dateToString } from '../../utils/date-utils';
import Calendar from '../Calendar';

const dropdownStyles = {
	container: {
		width: '100%',
	},
	title: {
		width: '100%',
		padding: '17px 10px',
	},
	menu: {
		minWidth: '100%',
	},
};

interface DateDropdownProps {
	from: Date;
	to: Date;
	onChange: (period: { from: Date; to: Date }) => void;
}

type PickDateOpenType = {
	isOpen: boolean;
	closeFn?: () => void;
};

const DateDropdown: React.FC<DateDropdownProps> = ({ from, to, onChange }) => {
	const [pickDateOpen, setPickDateOpen] = useState<PickDateOpenType>({
		isOpen: false,
	});
	const Title: React.FC = () =>
		!from && !to ? (
			<span className="gray">Date period: not selected</span>
		) : (
			<span style={{ whiteSpace: 'pre-wrap' }}>
				<span className="gray">Date period: </span>
				{dateToString(from)} - {dateToString(to)}
			</span>
		);
	const MenuContent = (
		closeMenu: () => void,
		dropdownRef: React.MutableRefObject<HTMLDivElement>,
	) => {
		const handleChange = (period: { start: Date; end: Date }) => {
			if (dropdownRef.current) {
				handleRangeChange(period);
			}
		};
		return (
			<Calendar
				value={{ start: from, end: to }}
				range
				onRangeChage={handleChange}
			/>
		);
	};
	const MenuContentWithCloseMenu = (
		closeMenu: () => void,
		dropdownRef: React.MutableRefObject<HTMLDivElement>,
	) => {
		const handleCloseMenu = () => {
			if (dropdownRef.current) {
				closeMenu();
			}
		};
		const handleChange = (period: { from: Date; to: Date }) => {
			if (dropdownRef.current) {
				onChange(period);
			}
		};
		return (
			<DateMenu
				closeMenu={handleCloseMenu}
				onChange={handleChange}
				handlePickDateClick={onPickDateOpen}
			/>
		);
	};
	const handleRangeChange = ({
		start: from,
		end: to,
	}: {
		start: Date;
		end: Date;
	}) => {
		onChange({ from, to });
		pickDateOpen.closeFn();
		setPickDateOpen({ isOpen: false });
	};
	const onPickDateOpen = (closeMenu: () => void) => {
		setPickDateOpen({ isOpen: true, closeFn: closeMenu });
	};
	return (
		<Dropdown
			title={Title}
			performOnClickOutside={() => {
				setPickDateOpen({ isOpen: false });
			}}
			render={pickDateOpen.isOpen ? MenuContent : MenuContentWithCloseMenu}
			isInitiallyOpen={pickDateOpen.isOpen}
			styles={dropdownStyles}
		/>
	);
};

export default DateDropdown;
