import React from 'react';

enum ButtonStyles {
	primary = 'primary',
	secondary = 'secondary',
	default = 'default',
}

enum ButtonTypes {
	submit = 'submit',
	reset = 'reset',
	button = 'button',
}

interface ButtonProps {
	children: React.ReactNode;
	type?: ButtonTypes;
	disabled?: boolean;
	buttonStyle?: ButtonStyles;
	onClick: (e: React.MouseEvent) => void;
}

interface StyledButtonProps {
	disabled: boolean;
	theme?: {
		button: {
			borderRadius: string;
			boxShadow: string;
			padding: string;
			margin: string;
			disabled: {
				backgroundColor: string;
				boxShadow: string;
				color: string;
			};
			primary: {
				backgroundColor: string;
				backgroundColorHover: string;
				color: string;
			};
			secondary: {
				backgroundColor: string;
				backgroundColorHover: string;
				color: string;
			};
			default: {
				backgroundColor: string;
				backgroundColorHover: string;
				color: string;
			};
		};
	};
	buttonStyle: ButtonStyles;
}

export { ButtonProps, StyledButtonProps, ButtonTypes, ButtonStyles };
