export interface StateProps extends FiltersProps {
	loading: boolean;
	isAddEntryInProgress: boolean;
	entries: Array<{
		id: string;
	}>;
	errorMessage?: string;
	nextPageToken?: string;
	isLoadingMore: boolean;
	set: (key: string, value: any) => StateProps;
	get: (key: string) => any;
}

export interface FiltersProps {
	pageSize: string;
	type: string;
	categoryId: string;
	sectionId: string;
	dateFrom?: string;
	dateTo?: string;
}

const setFilters = (state: StateProps, filters?: FiltersProps) => {
	if (!filters) {
		return state;
	}
	return state
		.set('categoryId', filters.categoryId)
		.set('sectionId', filters.sectionId)
		.set('type', filters.type)
		.set('pageSize', filters.pageSize)
		.set('dateFrom', filters.dateFrom)
		.set('dateTo', filters.dateTo);
};

export default setFilters;
