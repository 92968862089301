import React from 'react';
import styled from '@emotion/styled';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AppRouter from '../AppRouter';

const AppContainer = styled('div')`
	display: flex;
	min-height: 100vh;
	flex-direction: column;
`;

function AppLayout() {
	return (
		<AppContainer>
			<Header />
			<AppRouter />
			<Footer />
		</AppContainer>
	);
}

export default AppLayout;
