import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { appName } from '../../../../config/app.config';
import * as entriesService from '../utils/entriesService';
import setFilters from '../utils/setFilters';
import { LOGOUT_USER_SUCCESS } from './auth.duck';
import { allSections } from './sections.duck';
import { allCategories } from './categories.duck';

export const moduleName = 'entries';
const prefix = `${appName}/${moduleName}`;

const normailzeQuery = (query) => ({
  pageSize: query.pageSize,
  dateFrom: query.dateFrom,
  dateTo: query.dateTo,
  section: query.sectionId,
  category: query.categoryId,
  type: query.type,
});

// Selectors
const selectEntries = (state) => state.get(moduleName);
export const loading = () =>
  createSelector(selectEntries, (state) => state.get('loading'));
export const entries = () =>
  createSelector(selectEntries, (state) => state.get('entries'));
export const errorMessage = () =>
  createSelector(selectEntries, (state) => state.get('errorMessage'));
export const filters = () =>
  createSelector(selectEntries, (state) => ({
    pageSize: state.get('pageSize'),
    type: state.get('type'),
    categoryId: state.get('categoryId'),
    sectionId: state.get('sectionId'),
    dateFrom: state.get('dateFrom'),
    dateTo: state.get('dateTo'),
  }));
export const nextPageToken = () =>
  createSelector(selectEntries, (state) => state.get('nextPageToken'));
export const isLoadingMore = () =>
  createSelector(selectEntries, (state) => state.get('isLoadingMore'));
export const isAddEntryInProgress = () =>
  createSelector(selectEntries, (state) => state.get('isAddEntryInProgress'));

// Constants
export const GET_ENTRIES_STARTED = `${prefix}/GET_ENTRIES_STARTED`;
export const GET_ENTRIES_SUCCESS = `${prefix}/GET_ENTRIES_SUCCESS`;
export const GET_ENTRIES_FAIL = `${prefix}/GET_ENTRIES_FAIL`;
export const GET_ENTRIES = `${prefix}/GET_ENTRIES`;
export const GET_CATEGORIES = `${prefix}/GET_CATEGORIES`;
export const ADD_ENTRY = `${prefix}/ADD_ENTRY`;
export const ADD_ENTRY_STARTED = `${prefix}/ADD_ENTRY_STARTED`;
export const ADD_ENTRY_SUCCESS = `${prefix}/ADD_ENTRY_SUCCESS`;
export const ADD_ENTRY_FAIL = `${prefix}/ADD_ENTRY_FAIL`;
export const FILTERS_CHANGED = `${prefix}/FILTERS_CHANGED`;
export const LOAD_MORE = `${prefix}/LOAD_MORE`;
export const LOAD_MORE_SUCCESS = `${prefix}/LOAD_MORE_SUCCESS`;
export const LOAD_MORE_FAIL = `${prefix}/LOAD_MODE_FAIL`;
export const DELETE_ENTRY = `${prefix}/DELETE_ENTRY`;
export const DELETE_ENTRY_STARTED = `${prefix}/DELETE_ENTRY_STARTED`;
export const DELETE_ENTRY_SUCCESS = `${prefix}/DELETE_ENTRY_SUCCESS`;
export const DELETE_ENTRY_FAIL = `${prefix}/DELETE_ENTRY_FAIL`;
export const EDIT_ENTRY = `${prefix}/EDIT_ENTRY`;
export const EDIT_ENTRY_STARTED = `${prefix}/EDIT_ENTRY_STARTED`;
export const EDIT_ENTRY_FAIL = `${prefix}/EDIT_ENTRY_FAIL`;
export const EDIT_ENTRY_SUCCESS = `${prefix}/EDIT_ENTRY_SUCCESS`;
// Reducer
export const initialState = fromJS({
  loading: false,
  isAddEntryInProgress: false,
  entries: [],
  errorMessage: '',
  nextPageToken: '',
  pageSize: '20',
  type: '',
  categoryId: '',
  sectionId: '',
  isLoadingMore: false,
});

/**
 *
 * @param {Object} state
 * @param {boolean} state.loading
 * @param {Array} state.entries
 * @param {string} state.errorMessage
 * @param {Object} action
 * @param {string} action.type
 * @param {array|string|object|undefined} [action.payload]
 * @returns {Object}
 */
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ENTRIES_STARTED:
      return state.set('loading', true);
    case ADD_ENTRY_STARTED:
      return state.set('isAddEntryInProgress', true);
    case GET_ENTRIES_SUCCESS:
      const filters = payload.filters;
      const newState = setFilters(state, filters);
      return newState
        .set('loading', false)
        .set('entries', payload.result)
        .set('nextPageToken', payload.nextPageToken);
    case GET_ENTRIES_FAIL:
      return state.set('loading', false).set('errorMessage', payload);
    case ADD_ENTRY_FAIL:
      return state
        .set('isAddEntryInProgress', false)
        .set('errorMessage', payload);
    case ADD_ENTRY_SUCCESS:
      return state.set('isAddEntryInProgress', false);
    case LOGOUT_USER_SUCCESS:
      return fromJS({});
    case LOAD_MORE:
      return state.set('isLoadingMore', true);
    case LOAD_MORE_FAIL:
      return state.set('errorMessage', payload).set('isLoadingMore', false);
    case LOAD_MORE_SUCCESS:
      const { result, nextPageToken } = payload;
      return state
        .set('entries', state.get('entries').concat(result))
        .set('nextPageToken', nextPageToken)
        .set('isLoadingMore', false);
    case DELETE_ENTRY_STARTED:
      return state.set('loading', true);
    case DELETE_ENTRY_SUCCESS:
      return state.set('loading', false).set(
        'entries',
        state.get('entries').filter(({ id }) => id !== payload)
      );
    case DELETE_ENTRY_FAIL:
      return state.set('loading', false).set('errorMessage', payload);
    case EDIT_ENTRY_SUCCESS:
      return state.set(
        'entries',
        state
          .get('entries')
          .map((entry) => (entry.id === payload.id ? payload : entry))
      );
    default:
      return state;
  }
}

// Action creators
export function fetchEntriesRequest() {
  return {
    type: GET_ENTRIES,
  };
}

export function fetchEntriesStarted() {
  return {
    type: GET_ENTRIES_STARTED,
  };
}

export function fetchEntriesSuccess(payload) {
  return {
    type: GET_ENTRIES_SUCCESS,
    payload,
  };
}

export function fetchEntriesFail(payload) {
  return {
    type: GET_ENTRIES_FAIL,
    payload,
  };
}

export function addEntryRequest(payload) {
  return {
    type: ADD_ENTRY,
    payload,
  };
}

export const addEntryStarted = () => ({ type: ADD_ENTRY_STARTED });
export const addEntrySuccess = () => ({
  type: ADD_ENTRY_SUCCESS,
});
export const addEntryFail = (payload) => ({ type: ADD_ENTRY_FAIL, payload });

export const filtersChangedRequest = (payload) => ({
  type: FILTERS_CHANGED,
  payload,
});

export const loadMoreEntriesRequest = () => ({
  type: LOAD_MORE,
});
export const loadMoreSuccess = (payload) => ({
  type: LOAD_MORE_SUCCESS,
  payload,
});
export const loadMoreFail = (payload) => ({
  type: LOAD_MORE_FAIL,
  payload,
});

export const deleteEntryRequest = (payload) => ({
  type: DELETE_ENTRY,
  payload,
});

const deleteEntrySuccess = (payload) => ({
  type: DELETE_ENTRY_SUCCESS,
  payload,
});

const deleteEntryFail = () => ({
  type: DELETE_ENTRY_FAIL,
});

export const editEntryRequest = (payload) => ({
  type: EDIT_ENTRY,
  payload,
});

// Sagas
export function* getEntriesSaga({ payload }) {
  yield put(fetchEntriesStarted());
  let query;
  if (payload && payload.filters) {
    query = payload.filters;
  } else {
    query = yield select(filters());
  }
  try {
    const { result = [], nextPageToken } = yield call(
      entriesService.getEntries,
      normailzeQuery(query)
    );
    yield put(
      fetchEntriesSuccess({
        result,
        nextPageToken,
        filters: query,
      })
    );
  } catch (e) {
    yield put(fetchEntriesFail(e.message));
    console.error(
      `getEntriesSaga with payload: ${JSON.stringify(payload)} error: ${e}`
    );
  }
}

export function* addEntrySaga({ payload }) {
  yield put(addEntryStarted());
  try {
    yield call(entriesService.addEntry, payload);
    yield put(addEntrySuccess());
    const query = yield select(filters());
    yield* getEntriesSaga({ payload: query });
  } catch (e) {
    yield put(addEntryFail(e.message));
    console.error(
      `addEntrySaga with payload: ${JSON.stringify(payload)} error: ${e}`
    );
  }
}

function* deleteEntrySaga({ payload }) {
  yield put({ type: DELETE_ENTRY_STARTED });
  try {
    const { id } = yield call(entriesService.deleteEntry, payload);
    yield put(deleteEntrySuccess(id));
  } catch (e) {
    yield put(deleteEntryFail(e.message));
    console.error(
      `deleteEntrySaga with payload: ${JSON.stringify(payload)} error: ${e}`
    );
  }
}

function* editEntrySaga({ payload }) {
  yield put({ type: EDIT_ENTRY_STARTED });
  try {
    const updatedEntry = yield call(entriesService.editEntry, payload);
    yield put({ type: EDIT_ENTRY_SUCCESS, payload: updatedEntry });
  } catch (e) {
    console.error(e);
    yield put({ type: EDIT_ENTRY_FAILED, payload: e.message });
  }
}

export function* loadMoreSaga() {
  const token = yield select(nextPageToken());
  if (token) {
    try {
      const query = yield select(filters());
      let { result, nextPageToken } = yield call(entriesService.getEntries, {
        ...normailzeQuery(query),
        nextPageToken: token,
      });
      // result = null returns if there are no sections
      if (!result) {
        result = [];
      }
      yield put(loadMoreSuccess({ result, nextPageToken }));
    } catch (e) {
      yield put(loadMoreFail(e.message));
      console.error(`loadMoreSaga error: ${e}`);
    }
  }
}

function* filtersChangedSaga({ payload }) {
  const { filter, value } = payload;
  const currentFilters = yield select(filters());
  switch (filter) {
    case 'type':
      if (currentFilters.type !== value) {
        currentFilters.type = value;
        currentFilters.sectionId = '';
        currentFilters.categoryId = '';
      }
      break;
    case 'sectionId':
      const sections = yield select(allSections());
      const { type } = sections.find((section) => section.id === value);
      if (currentFilters.sectionId !== value) {
        currentFilters.sectionId = value;
        currentFilters.categoryId = '';
        currentFilters.type = type;
      }
      break;
    case 'categoryId':
      const categories = yield select(allCategories());
      const { section } = categories.find((category) => category.id === value);
      if (currentFilters.categoryId !== value) {
        currentFilters.categoryId = value;
        currentFilters.sectionId = section.id;
        currentFilters.type = section.type;
      }
      break;
    case 'period':
      const { dateFrom, dateTo } = value;
      currentFilters.dateFrom = dateFrom;
      currentFilters.dateTo = dateTo;
      break;
    case 'pageSize':
      currentFilters.pageSize = value;
      break;
  }
  payload.filters = currentFilters;
  yield* getEntriesSaga({ payload });
}

function* filtersChanged() {
  yield takeEvery(FILTERS_CHANGED, filtersChangedSaga);
}

function* getEntries() {
  yield takeEvery(GET_ENTRIES, getEntriesSaga);
}

function* addEntry() {
  yield takeEvery(ADD_ENTRY, addEntrySaga);
}

function* deleteEntry() {
  yield takeEvery(DELETE_ENTRY, deleteEntrySaga);
}

function* editEntry() {
  yield takeEvery(EDIT_ENTRY, editEntrySaga);
}

export function* loadMore() {
  yield takeEvery(LOAD_MORE, loadMoreSaga);
}

export function* entriesRootSaga() {
  yield all([
    getEntries(),
    addEntry(),
    filtersChanged(),
    loadMore(),
    deleteEntry(),
    editEntry(),
  ]);
}
