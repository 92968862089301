import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../InputField';
// Emotion 10
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { isFieldValid } from '../../utils/authFormUtils/utils';

const styledInput = css`
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 5px 0;
    border: 1px solid #4e4e4e;
    border-radius: 2px;
    padding: 7px 10px;
    font-size: 14px;
    
    &:focus {
        outline: none;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }
`;

const withError = css`
    border-color: #efa1a1;
`;

const Input = (props) => {
  const { component: Component, ...rest } = props;

  switch (Component) {
    case 'input':
      return (
        <InputField {...rest} />
      );
    default:
      return (
        <Component
          required={props.isRequired}
          data-test="inputComponent"
          onFocus={e => props.onFocus(props.name, e)}
          onChange={e => props.onChange(props.name, e)}
          css={[styledInput, isFieldValid(props.errors) ? '' : withError]}
          {...rest}
        />
      );
  }
};

Input.propTypes = {
  component: PropTypes.oneOf(['input', 'textarea']),
  type: PropTypes.oneOf(['text', 'email', 'password']),
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  errors: PropTypes.shape(),
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
};


Input.defaultProps = {
  component: 'input',
  type: 'text',
  name: '',
  value: '',
  errors: {},
  isRequired: false,
  onFocus: () => { },
  onBlur: () => { },
  placeholder: '',
};

export default Input;
