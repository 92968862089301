import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ErrorBoundary from 'ui-lib/src/components/ErrorBoundary';

import { loggedIn } from '../../../ducks/auth.duck';

const PrivateRoute = props => {
	const { component: Component, authenticated, ...rest } = props;
	if (typeof authenticated === 'undefined') {
		return null;
	}
	return authenticated ? (
		<ErrorBoundary>
			<Component {...rest} />
		</ErrorBoundary>
	) : (
		<Redirect to="login" noThrow />
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.shape().isRequired,
	path: PropTypes.string.isRequired,
};

const mapDispatchToProps = createStructuredSelector({
	authenticated: loggedIn(),
});

export default connect(
	mapDispatchToProps,
	null,
)(PrivateRoute);
