import React from 'react';
import { ButtonProps, ButtonStyles, ButtonTypes } from './types';

import ButtonContainer from './ButtonContainer';

const Button: React.FC<ButtonProps> = ({
	children,
	type = ButtonTypes.button,
	disabled = false,
	buttonStyle = ButtonStyles.default,
	onClick,
}) => {
	return (
		<ButtonContainer
			type={type}
			disabled={disabled}
			buttonStyle={buttonStyle}
			onClick={onClick}
		>
			{children}
		</ButtonContainer>
	);
};

export default Button;
