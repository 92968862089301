import React from 'react';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer, { moduleName, entriesRootSaga } from '../../ducks/entries.duck';
import { compose } from 'redux';

const InjectableEntries = () => {
	return null;
};

const withSaga = injectSaga({ key: moduleName, saga: entriesRootSaga });
const withReducer = injectReducer({ key: moduleName, reducer });

export default compose(
	withReducer,
	withSaga,
)(InjectableEntries);
