import React from 'react';
import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer, {
	moduleName,
	categoriesRootSaga,
} from '../../ducks/categories.duck';

const InjectableCategories = () => {
	return null;
};

const withSaga = injectSaga({ key: moduleName, saga: categoriesRootSaga });
const withReducer = injectReducer({ key: moduleName, reducer });

export default compose(
	withReducer,
	withSaga,
)(InjectableCategories);
