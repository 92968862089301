import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const itemsPerPage = ['20', '50', '100'];

const PaginationComponent = ({ perPage, changeCount, itemsName }) => {
	const handleClick = e => {
		const newPerPage = e.target.innerText.trim();
		if (newPerPage !== perPage && itemsPerPage.indexOf(newPerPage) > -1) {
			changeCount(newPerPage);
		}
	};
	return (
		<div style={{ margin: '10px 0' }} onClick={handleClick}>
			Show{' '}
			{itemsPerPage.map(item => (
				<ItemsContainer key={item} active={perPage == item}>
					{item}{' '}
				</ItemsContainer>
			))}
			{itemsName} per page
		</div>
	);
};

PaginationComponent.propTypes = {
	perPage: PropTypes.oneOf(['20', '50', '100']),
	changeCount: PropTypes.func,
	itemsName: PropTypes.string,
};

PaginationComponent.defaultProps = {
	perPage: '20',
	changeCount: () => {},
	itemsName: 'Items',
};

export default PaginationComponent;

const ItemsContainer = styled.button`
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
	color: ${({ theme, active }) =>
		active ? theme.backgroundPrimary : theme.colorPrimary};
`;
