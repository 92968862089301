const DatePatterns = {
	'yyyy-mm-dd': 'yyyy-mm-dd',
	'dd/mm/yyyy': 'dd/mm/yyyy',
	fullDate: 'fullDate',
};

const daysList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const monthList = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const addZero = (n: number): string => (n < 10 ? `0${n}` : `${n}`);

const convertDateTo = ({
	date,
	pattern,
}: {
	date: Date;
	pattern: string;
}): string => {
	if (!date) return null;
	const year = date.getFullYear();
	let month = date.getMonth() + 1; // starts from 0 to 11
	const day = date.getDate();
	const dayOfWeek = date.getDay();
	switch (pattern) {
		case DatePatterns['dd/mm/yyyy']:
			return `${addZero(day)}/${addZero(month)}/${year}`;
		case DatePatterns.fullDate:
			return `${daysList[dayOfWeek]}, ${monthList[month - 1]} ${day}, ${year}`;
		case DatePatterns['yyyy-mm-dd']:
		default:
			return `${year}-${addZero(month)}-${addZero(day)}`;
	}
};

const convertToDate = ({
	dateString,
	pattern,
}: {
	dateString: string;
	pattern: string;
}): Date => {
	switch (pattern) {
		case DatePatterns['dd/mm/yyyy']: {
			const [day, month, year] = dateString.split('/').map(parseInt);
			return new Date(year, month - 1, day);
		}
		case DatePatterns.fullDate: {
			const [, monthWord, day, year] = dateString.split(' ');
			return new Date(
				parseInt(year),
				monthList.indexOf(monthWord) + 1,
				parseInt(day.substr(0, day.length - 1)),
			);
		}
		case DatePatterns['yyyy-mm-dd']:
		default: {
			const [year, month, day] = dateString.split('-').map(el => parseInt(el));
			return new Date(year, month - 1, day);
		}
	}
};

const convertFromTimestamp = ({
	timestamp,
	pattern,
}: {
	timestamp: number;
	pattern: string;
}) => convertDateTo({ date: new Date(timestamp), pattern });

export { convertDateTo, convertToDate, DatePatterns, convertFromTimestamp };
