import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { fillRowsInCalendar } from '../../utils/date-utils';

const daysList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

enum dayMonth {
	cur = 'cur',
	prev = 'prev',
	next = 'next',
}

interface DayProps {
	month: dayMonth;
	day: number;
}

const DaysRow: React.FC = () => (
	<Row>
		{daysList.map(day => (
			<Item key={day}>{day}</Item>
		))}
	</Row>
);

interface RowsProps {
	date: Date;
	onChange: (day: DayProps) => void;
	value: Date;
}

const Rows: React.FC<RowsProps> = ({ date, onChange, value }) => {
	const rows = fillRowsInCalendar(date);
	const isCurrentMonth = value && value.getMonth() === date.getMonth();
	const isCurrentYear = value && value.getFullYear() === date.getFullYear();
	const isCurrent = isCurrentMonth && isCurrentYear;
	const selectedDay = value && isCurrent && value.getDate();
	return (
		<Fragment>
			{rows.map((row, index) => (
				<Row key={index}>
					{row.map(day => (
						<Day
							key={`${day.day}-${day.month}`}
							day={day}
							onChange={onChange}
							selected={selectedDay}
						/>
					))}
				</Row>
			))}
		</Fragment>
	);
};

interface DayComponentProps {
	day: DayProps;
	onChange: (day: DayProps) => void;
	selected: number;
}

const Day: React.FC<DayComponentProps> = ({ day, onChange, selected }) => {
	const handleClick = () => {
		onChange(day);
	};
	return (
		<Item
			onClick={handleClick}
			isSelected={day.month === 'cur' && selected === day.day}
		>
			{day.day}
		</Item>
	);
};

interface DaysProps {
	date: Date;
	onChange: (day: DayProps) => void;
	value: Date;
}

const Days: React.FC<DaysProps> = ({ date, onChange, value }) => (
	<div>
		<DaysRow />
		<Rows date={date} onChange={onChange} value={value} />
	</div>
);

export default Days;

/*----------------- Styles -----------------*/
const Row = styled.div`
	display: flex;
	flex-wrap: nowrap;
`;
const Item = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: ${({ isSelected }: { isSelected?: boolean }) =>
		isSelected ? 'red' : 'inhereted'};
`;
