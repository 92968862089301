import { compose } from 'redux';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import reducer, { moduleName, chartsRootSaga } from '../../ducks/charts.duck';

const InjectableCharts = () => {
  return null;
};

const withSaga = injectSaga({ key: moduleName, saga: chartsRootSaga });
const withReducer = injectReducer({ key: moduleName, reducer });

export default compose(withReducer, withSaga)(InjectableCharts);
