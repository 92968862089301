import { getWithQuery, addRequest, getBy, deleteBy } from './userRequests';
import { urlBases } from './constants';
/**
 * @param {Object} body
 * @param {string} body.name
 * @param {string} body.type
 * @returns {undefined | Object}
 */
function addSection(body) {
	const { name, type } = body;
	if (!name || !type) {
		return undefined;
	}
	return addRequest(urlBases.sections, body);
}

/**
 *
 * @param {Object} [query]
 * @param {number} [query.pageSize]
 * @param {string} [query.nextPageToken]
 * @param {string} [query.type]
 * @returns {Array}
 */
const getSections = query => getWithQuery(urlBases.sections, query);

/**
 *
 * @returns {Promise<Response>}
 */
const getAllSections = () =>
	getWithQuery(urlBases.sections, { pageSize: 1000 });

/**
 *
 * @param {string} id
 * @returns {undefined | Promise<Response>}
 */
const getSection = id => getBy(urlBases.sections, id);

/**
 *
 * @param {string} id
 * @returns {undefined | Promise<Response>}
 */
const deleteSection = id => deleteBy(urlBases.sections, id);

export { addSection, getSections, getSection, deleteSection, getAllSections };
