import React from 'react';
import styled from '@emotion/styled';
import LoaderIcon from '../icons/Loader';

const Loader = styled('div')`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LoadingIndicator = () => {
	return (
		<Loader>
			<LoaderIcon />
		</Loader>
	);
};

export default LoadingIndicator;
