import { css } from '@emotion/core';

const breakpoints = [425, 768, 1024, 1440];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

export const globalStyles = css`
	body {
		font-size: 18px;
		line-height: 24px;
	}
	* {
		box-sizing: border-box;
	}
	.page {
		flex: auto;
	}
	.pageContainer {
		margin: auto;
		max-width: 1280px;
		padding: 30px;
		display: flex;
		height: 100%;
	}
	.sidebar {
		flex: 0 0 30%;
		padding-left: 60px;
		border-left: 1px solid #ddd;
	}
	.main {
		flex: 0 1 70%;
		padding-right: 60px;
	}
	.flex-row {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-wrap: wrap;
	}
	.table {
		width: 100%;
		margin: 0 auto;
		font-size: 16px;
		line-height: 24px;
	}
	.tableRow {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		padding: 6px 0;
		border-bottom: 1px solid #eee;
	}
	.tableRowItem {
		flex: 1 1 50%;
		padding: 0 5px;
		word-break: break-word;
	}
	.smallRow {
		flex: 0 0 90px;
	}
	.middleRow {
		flex: 0 0 150px;
	}
	.tableRowHeaderItem {
		font-weight: bold;
	}
	.tableBody {
		position: relative;
		min-height: 330px;
	}
	.nothingHere {
		font-size: 24px;
		line-height: 36px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.active-nav {
		position: relative;
	}
	.active-nav::before {
		position: absolute;
		bottom: 0;
		width: 100% !important;
		height: 2px;
		background: #fff;
		content: '';
		display: block;
	}
	.emptyBlock {
		font-size: 16px;
		padding: 20px 30px;
		a {
			color: #2196f3;
			text-decoration: none;
		}
	}
	.modalBody {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.gray {
		color: #666;
	}

	${mq[2]} {
		.hide-1024 {
			display: none;
		}
	}
	${mq[1]} {
		.pageContainer {
			flex-direction: column-reverse;
		}
		.sidebar {
			flex: 0 0 auto;
			padding-left: 0;
			border-left: none;
		}
		.main {
			flex: 0 0 auto;
			padding-right: 0;
		}
		.active-nav::before {
			width: 3px !important;
			left: 0 !important;
			top: 10%;
			height: 80% !important;
			background: #fff;
			content: '';
			display: block;
		}
		.emptyBlock {
			font-size: 14px;
			padding: 20px 10px;
		}
	}
`;
