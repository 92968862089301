import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

function Select(props) {
  const {
    options,
    handleChange,
    name,
    value,
  } = props

  const handleSelectChange = e => handleChange(name, e)
  return (
    <select
      id="standard-select-currency"
      select
      label="Select"
      value={value}
      onChange={handleSelectChange}
      helperText="Please select a type"
      margin="normal"
    >
      {options.map(option => (
        <option key={option.value} value={option.value || option.title}>
          {option.title}
        </option>
      ))}
    </select>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  initialValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string,
}

Select.defaultProps = {
  initialValue: undefined,
  name: '',
}

export default Select
