import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CloseIcon } from 'ui-lib/src';

const Outline = styled('div')`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	min-height: 100vh;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.3);
	z-index: 3;
`;

const Container = styled('div')`
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	border-radius: 3px;
	width: 600px;
	@media (max-width: 640px) {
		top: 0;
		width: 100%;
	}
`;

const Close = styled.button`
	background: none;
	border: none;
	width: 20px;
	height: 20px;
	padding: 0;
	outline: none;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
`;

const Header = styled.div`
	padding: 20px;
	background: #eee;
	color: #555;
	font-weight: bold;
	font-size: 18px;
	line-height: 27px;
`;

const Content = styled.div`
	padding: 30px 20px;
`;

const modalEl = document.getElementById('modal-root');

export class Modal extends Component {
	static props = {
		closeHandler: PropTypes.func,
		header: PropTypes.string,
	};

	static defaultProps = {
		closeHandler: () => {},
		header: '',
	};

	modalRoot = document.createElement('div');

	componentDidMount() {
		if (modalEl) {
			modalEl.appendChild(this.modalRoot);
		} else {
			// else block only for storybook DON'T FORGET TO REMOVE IT
			const modalRoot = document.createElement('div');
			modalRoot.setAttribute('id', 'modal-root');
			document.body.appendChild(modalRoot);
			modalRoot.appendChild(this.modalRoot);
		}
	}

	componentWillUnmount() {
		if (modalEl) {
			modalEl.removeChild(this.modalRoot);
		} else {
			// else block only for storybook DON'T FORGET TO REMOVE IT
			const modalRoot = document.getElementById('modal-root');
			modalRoot.removeChild(this.modalRoot);
		}
	}

	render() {
		const { children, closeHandler, header } = this.props;

		const Portal = (
			<Outline>
				<Container>
					<Close onClick={closeHandler}>
						<CloseIcon />
					</Close>
					{header && <Header>{header}</Header>}
					<Content>{children}</Content>
				</Container>
			</Outline>
		);
		return createPortal(Portal, this.modalRoot);
	}
}

export default Modal;
