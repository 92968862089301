import React, { useState, useRef } from 'react';
import Menu from './Menu';
import styled from '@emotion/styled';

type TitleProps = {
	isOpen: boolean;
};

interface DropdownProps {
	title: React.FC<TitleProps>;
	styles?: {
		container?: {};
		title?: {};
		menu?: {};
	};
	closeOnClick?: boolean;
	performOnClickOutside?: () => void;
	isInitiallyOpen?: boolean;
	render: (
		closeMenu: () => void,
		dropdownRef: React.MutableRefObject<HTMLDivElement>,
	) => React.ReactNode;
}

const Dropdown: React.FC<DropdownProps> = ({
	title: Title,
	styles,
	closeOnClick,
	performOnClickOutside,
	render,
	isInitiallyOpen = false,
}) => {
	const dropdownRef = useRef(null);
	const [isOpen, setIsOpen] = useState(isInitiallyOpen);
	const toggleIsOpen = () => {
		if (isOpen === true && performOnClickOutside) {
			performOnClickOutside();
		}
		setIsOpen(!isOpen);
	};
	const closeMenu = () => {
		setIsOpen(false);
	};
	const handleMenuClick = (e: React.MouseEvent) => {
		if (closeOnClick && isOpen) {
			e.nativeEvent.stopImmediatePropagation();
			setIsOpen(false);
		}
	};
	const handleClickOutside = () => {
		if (performOnClickOutside) {
			performOnClickOutside();
		}
		closeMenu();
	};

	return (
		<DropdownContainer style={styles && styles.container} ref={dropdownRef}>
			<DropdownTitle onClick={toggleIsOpen} style={styles && styles.title}>
				<Title isOpen={isOpen} />
			</DropdownTitle>
			{isOpen && (
				<Menu
					onClickOutside={handleClickOutside}
					style={styles && styles.menu}
					handleMenuClick={handleMenuClick}
				>
					{render && render(closeMenu, dropdownRef)}
				</Menu>
			)}
		</DropdownContainer>
	);
};

export default Dropdown;

/*------------ STYLES ------------*/
const DropdownContainer = styled.div`
	position: relative;
	display: inline-flex;
	flex-direction: column;
`;
const DropdownTitle = styled.div`
	padding: 2px 8px;
	border: 1px solid #aaa;
	border-radius: 3px;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	justify-content: space-between;

	& svg {
		margin-left: 5px;
	}
`;
