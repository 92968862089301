import {
	getWithQuery,
	addRequest,
	deleteBy,
	editRequest,
} from './userRequests';
import { urlBases } from './constants';
import * as dateFns from './date-format';

/**
 * @param {Object} body
 * @param {Number} body.sum
 * @param {String} body.comment
 * @param {String} body.categoryId
 * @param {Number} body.date
 * @returns {undefined | Object}
 */
function addEntry(body) {
	const { sum, categoryId, date } = body;
	if (!sum || !categoryId) {
		return undefined;
	}

	return addRequest(urlBases.entries, {
		...body,
		date: dateFns.convertDateTo({
			date,
			pattern: dateFns.DatePatterns['yyyy-mm-dd'],
		}),
	});
}

/**
 *
 * @param {Object} [query]
 * @param {number} [query.pageSize]
 * @param {string} [query.nextPageToken]
 * @param {string} [query.sectionId]
 * @param {string} [query.categoryId]
 * @returns {Array}
 */
const getEntries = query => getWithQuery(urlBases.entries, query);

/**
 *
 * @param {string} id
 */
const deleteEntry = id => {
	if (!id) {
		throw new Error('entry id is not provided');
	}
	return deleteBy(urlBases.entries, id);
};

const editEntry = ({ id, ...rest }) => {
	if (!id) {
		console.error('entry id is missing in editEntry');
	}
	return editRequest(
		urlBases.entries,
		{
			...rest,
			date: dateFns.convertDateTo({
				date: rest.date,
				pattern: dateFns.DatePatterns['yyyy-mm-dd'],
			}),
		},
		id,
	);
};

export { addEntry, getEntries, deleteEntry, editEntry };
