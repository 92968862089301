import React, { useEffect, createRef } from 'react';
import styled from '@emotion/styled';

const FOOTER_HEIGHT = 20;
const HEADER_HEIGHT = 50;

interface DropdownMenuProps {
  onClickOutside?: () => void;
  style?: {
    top?: string;
  };
  handleMenuClick: (e: React.MouseEvent) => void;
}

const Menu: React.FC<DropdownMenuProps> = ({
  children,
  onClickOutside,
  style,
  handleMenuClick,
}) => {
  const menuRef = createRef<HTMLDivElement>();
  const clickListener = (e: MouseEvent) => {
    if (menuRef.current && e.target instanceof HTMLElement) {
      const isMenuClicked =
        menuRef.current === e.target ||
        menuRef.current.parentNode.contains(e.target);
      if (!isMenuClicked) {
        onClickOutside();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('click', clickListener);
    if (menuRef && menuRef.current) {
      // reset menu styles
      const menuElement = menuRef.current;
      menuElement.style.opacity = '0';
      menuElement.style.top = (style && style.top) || '100%';
      menuElement.style.bottom = 'initial';
      // reset menu styles
      const dim = menuRef.current.getBoundingClientRect();
      const windowHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
      if (
        windowHeight <= dim.bottom + FOOTER_HEIGHT &&
        dim.top - dim.height >= HEADER_HEIGHT
      ) {
        menuRef.current.style.top = 'initial';
        menuRef.current.style.bottom = '100%';
      }
      const windowWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      if (windowWidth < dim.right) {
        menuRef.current.style.left = `${windowWidth - dim.right - 20}px`;
      } else if (dim.left < 0) {
        menuRef.current.style.left = '20px';
      }
      menuRef.current.style.opacity = '1';
    }
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [menuRef]);
  return (
    <MenuContainer ref={menuRef} style={style} onClick={handleMenuClick}>
      {children}
    </MenuContainer>
  );
};

export default Menu;

/*------------ STYLES ------------*/
const MenuContainer = styled.div`
  position: absolute;
  top: 100%;
  opacity: 0;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  width: auto;
  min-width: 100%;
  background: #fff;
  box-shadow: ${({ theme }) => `0px 25px 25px ${theme.boxShadowColor}`};

  & ul {
    padding: 0;
    margin: 0;
    list-style: none;
    min-width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }
  & li {
    padding: 2px 10px;
    cursor: pointer;
  }
  & li:hover {
    background: #eee;
  }
`;
