export default function constructUrlFromQuery(query) {
	let stringFromQuery = "";
	if (query) {
		const keys = Object.keys(query);
		stringFromQuery += "?";
		for (let i = 0; i < keys.length; i++) {
			if (query[keys[i]]) {
				stringFromQuery += `${keys[i]}=${query[keys[i]]}${
					i < keys.length - 1 ? "&" : ""
				}`;
			}
		}
	}
	return stringFromQuery;
}
