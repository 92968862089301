import React from 'react';
import styled from '@emotion/styled';

const FooterContainer = styled('div')`
	width: 100%;
	background-color: ${({ theme }) => theme.backgroundPrimary};
	height: 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: ${({ theme }) => theme.zIndex.header};
`;

const Footer = () => <FooterContainer data-test="footer-container" />;

export default Footer;
