import React, { PureComponent, Fragment } from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import InjectableSections from './Injectable/InjectableSections';
import InjectableCategories from './Injectable/InjectableCategories';
import InjectableEntries from './Injectable/InjectableEntries';
import InjectableCharts from './Injectable/InjectableCharts';
import injectSaga from '../utils/injectSaga';
import Button from 'ui-lib/src/components/Button';
import { StatsDots } from 'ui-lib/src';
import Menu from 'ui-lib/src/components/Dropdown/Menu';
import {
	logoutUser,
	loggedIn,
	authRootSaga,
	moduleName,
} from '../ducks/auth.duck';

const HeaderContainer = styled('div')`
	background: ${({ theme }) => theme.backgroundPrimary};
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.8);
	position: fixed;
	z-index: ${({ theme }) => theme.zIndex.header};
	width: 100%;
`;
const HeaderInnerContainer = styled('div')`
	margin: auto;
	padding: 0 20px;
	max-width: ${({ theme }) => theme.containerWidth};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const RightContainer = styled('div')`
	display: flex;
	align-items: center;
`;
const LeftContainer = styled('div')`
	display: flex;
	align-items: center;
`;
const LoginRegisterBtn = styled(Link)`
	display: inline-block;
	margin: 7px 10px;
	color: #fff;
	text-decoration: none;
	position: relative;
	&::before {
		display: block;
		content: '';
		background: #fff;
		width: 0;
		height: 2px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.5s;
	}
	&:hover,
	&:active {
		cursor: pointer;
		&::before {
			width: 100%;
		}
	}
`;

const NavContainer = styled.nav`
	& ul {
		list-style: none;
		display: flex;
	}
	& li {
		margin-right: 20px;
	}
	& a {
		color: #fff;
		font-weight: bold;
		text-decoration: none;
		display: inline-block;
		position: relative;
		&::before {
			display: block;
			content: '';
			background: #fff;
			width: 0;
			height: 2px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			transition: all 0.5s;
		}
		&:hover,
		&:active {
			cursor: pointer;
			&::before {
				width: 100%;
			}
		}
	}
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		& > ul {
			display: none !important;
		}
		& ul {
			display: ${({ isNavOpen }) => (isNavOpen ? 'block' : 'none')};
			margin: 0;
			background: ${({ theme }) => theme.backgroundPrimary};
			position: absolute;
			top: 100%;
			left: 0;
			box-shadow: 0 15px 15px rgba(3, 3, 3, 0.3);
		}
		& li {
			margin-right: 0;
		}
		& a {
			display: block;
			padding: 20px 0;
			text-align: center;
			cursor: pointer;
		}
`;
const Burger = styled.div`
	display: flex;
	width: 40px;
	height: 30px;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	margin: 0 20px;
	& span {
		width: 100%;
		height: 3px;
		background: #fff;
	}
	&::before,
	&::after {
		width: 100%;
		height: 3px;
		background: #fff;
		content: '';
		display: block;
	}
	@media (min-width: 769px) {
		display: none;
	}
`;

const PageTitle = styled.div`
	font-size: 21px;
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	@media (min-width: 769px) {
		display: none;
	}
	@media (max-width: 400px) {
		display: none;
	}
`;

const NavLink = props => (
	<li>
		<Link
			{...props}
			getProps={({ isCurrent }) => isCurrent && { className: 'active-nav' }}
		/>
	</li>
);

export class Header extends PureComponent {
	static propTypes = {
		authenticated: PropTypes.bool,
		handleLogout: PropTypes.func.isRequired,
	};

	static defaultProps = {
		authenticated: false,
	};

	state = {
		isNavOpen: false,
	};

	toggleNavOpen = () => {
		this.setState(prevState => ({
			isNavOpen: !prevState.isNavOpen,
		}));
	};
	closeMenu = () => {
		this.setState({
			isNavOpen: false,
		});
	};
	handleMenuClick = e => {
		const { isNavOpen } = this.state;
		if (isNavOpen) {
			e.nativeEvent.stopImmediatePropagation();
			this.setState({
				isNavOpen: false,
			});
		}
	};

	get navMenu() {
		const { authenticated } = this.props;
		const { isNavOpen } = this.state;
		const getTitle = () => {
			const { pathname } = window.location;
			switch (pathname) {
				case '/':
					return 'dashboard';
				case '/sections':
					return 'sections';
				case '/categories':
					return 'categories';
				case '/entries':
					return 'entries';
				default:
					return;
			}
		};
		const title = getTitle();
		return (
			authenticated && (
				<NavContainer data-test="nav-menu" isNavOpen={isNavOpen}>
					<InjectableSections />
					<InjectableCategories />
					<InjectableEntries />
					<InjectableCharts />
					<ul>
						<NavLink to="sections">Sections</NavLink>
						<NavLink to="categories">Categories</NavLink>
						<NavLink to="entries">Entries</NavLink>
					</ul>
					<Burger onClick={this.toggleNavOpen}>
						<span />
					</Burger>
					{title && <PageTitle>{title}</PageTitle>}
					{isNavOpen && (
						<Menu
							onClickOutside={this.closeMenu}
							handleMenuClick={this.handleMenuClick}
							style={{ left: '0' }}
						>
							<ul onClick={this.closeMenu}>
								<NavLink to="sections">Sections</NavLink>
								<NavLink to="categories">Categories</NavLink>
								<NavLink to="entries">Entries</NavLink>
							</ul>
						</Menu>
					)}
				</NavContainer>
			)
		);
	}

	get authButtons() {
		const { authenticated, handleLogout } = this.props;
		if (authenticated) {
			return (
				<Button onClick={handleLogout} data-test="logout-button">
					Logout
				</Button>
			);
		}
		return (
			<Fragment>
				<LoginRegisterBtn to="login" data-test="login-button">
					Login
				</LoginRegisterBtn>
				<LoginRegisterBtn to="register" data-test="register-button">
					Register
				</LoginRegisterBtn>
			</Fragment>
		);
	}

	render() {
		return (
			<HeaderContainer data-test="header-container">
				<HeaderInnerContainer>
					<RightContainer>
						<Link to="">
							<StatsDots color="#fff" />
						</Link>
						{this.navMenu}
					</RightContainer>
					<LeftContainer>{this.authButtons}</LeftContainer>
				</HeaderInnerContainer>
			</HeaderContainer>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	authenticated: loggedIn(),
});

const mapDispatchToProps = dispatch => ({
	handleLogout: () => dispatch(logoutUser()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: moduleName, saga: authRootSaga });

export default compose(withSaga, withConnect)(Header);
